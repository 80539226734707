<template>
  <div class="view-contanier">
    <div class="opt">
      <div class="tab">
        <span
          v-permissson="'ZDZH'"
          class="tabItem"
          :class="{ active: active === 1 }"
          @click="changeType(1, 'ZDZH')"
        >制单账号</span
        >
        <span
          v-permissson="'RYSZ'"
          class="tabItem"
          :class="{ active: active === 2 }"
          @click="changeType(2, 'RYSZ')"
        >燃油设置</span
        >
        <span
          v-permissson="'KHZHMB'"
          class="tabItem"
          :class="{ active: active === 3 }"
          @click="changeType(3, 'KHZHMB')"
        >客户账号模板</span
        >
      </div>
      <div style="display: flex">
        <div
          v-show="newAdd"
          :v-permissson="newAdd"
          class="btn"
          @click="add"
        >
          <svg-icon className="icon" iconClass="add" />新增
        </div>
        <BaseFilter
          :isActive="isActive"
          :width="active === 1 ? 480 : 440"
          @sendFilter="sendFilter"
          @reset="reset"
        >
          <div class="filter">
            <el-form ref="form" :model="filterList" :label-width="active === 1 ? '82px' : '40px'">
              <el-form-item v-if="active === 1" label="账号">
                <el-input
                  v-model="filterList.search"
                  placeholder="请输入账号"
                  style="width: 320px"
                />
              </el-form-item>
              <el-form-item v-show="active === 1" label="快递服务商">
                <el-select
                  v-model="filterList.expressSupplierId"
                  style="width: 320px"
                  placeholder="请选择快递服务商"
                >
                  <el-option
                    v-for="item in esOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="分类">
                <el-select
                  v-model="filterList.quoteType"
                  style="width: 320px"
                  placeholder="请选择分类"
                >
                  <el-option value="Domestic" label="本土快递"></el-option>
                  <el-option value="International" label="国际快递"></el-option>
                  <el-option value="Truck" label="卡派"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item v-if="active === 2" label="国家">
                <el-select
                  v-model="filterList.countryId"
                  style="width: 320px"
                  placeholder="请选择国家"
                >
                  <el-option
                    v-for="item in addressDropOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </BaseFilter>
      </div>
    </div>
    <div class="data">
      <!-- :key="active" -->
      <BaseTable
        v-if="height !== 0"
        :height="height"
        :tableData="tableTotalData"
        :pageIndex="pagerConfig.pageIndex"
        :pageSize="pagerConfig.pageSize"
        :loading="loading"
        @handlePageChange="handlePageChange"
        @sortChange="sortChange"
      >
        <template slot="isActived" slot-scope="row">
          <div
            class="circle"
            :style="{
              'background-color': row.scope.isActived ? ' #35BA83' : '#FE453D',
            }"
          ></div>
          {{ row.scope.isActived ? "启用" : "停用" }}
        </template>
        <template slot="serviceProviderName" slot-scope="row">
          {{ row.scope.serviceProviderName }}
          <img class="outDate" :src="isOutDate(row.scope.validTo)" alt="">
        </template>
        <template slot="isActived" slot-scope="row">
          <div
            class="circle"
            :style="{
              'background-color': row.scope.isActived ? ' #35BA83' : '#FE453D',
            }"
          ></div>
          {{ row.scope.isActived ? "启用" : "停用" }}
        </template>
        <template slot="address" slot-scope="row">
          <el-tooltip
            class="item"
            effect="dark"
            :tabindex="2000"
            placement="bottom"
          >
            <div slot="content">
              {{
                `${row.scope.countryId}/${row.scope.provinceId}/${row.scope.cityId}`
                  | formatterAddress
              }}{{ row.scope.address }}
            </div>
            <svg-icon iconClass="address" className="icon" />
          </el-tooltip>
        </template>
        <template slot="remark" slot-scope="row">
          <el-tooltip
            class="item"
            effect="dark"
            :tabindex="2000"
            :content="row.scope.remark"
            placement="bottom"
          >
            <svg-icon iconClass="remark" className="icon" />
          </el-tooltip>
        </template>
        <template slot="currentAmount" slot-scope="row">
          <el-popover
            placement="bottom-start"
            width="340"
            trigger="hover">
            <div slot="reference">
              <div class="amount-mark">
                信用额度：{{ row.scope.creditAmount | formarAmount(row.scope.currency) }}
              </div>
              <div class="amount-mark">
                当前余额：{{ row.scope.currentAmount | formarAmount(row.scope.currency) }}
              </div>
            </div>
            <div class="current_box">
              <div class="current-items">
                <span>当前余额</span>
                <span>{{ row.scope.currentAmount }}</span>
              </div>
              <div class="current-items">
                <span>信用额度</span>
                <span>{{ row.scope.creditAmount }}</span>
              </div>
              <div class="current-items">
                <span>余额更新时间</span>
                <span>{{ row.scope.balanceUpdateOn || '' }}</span>
              </div>
            </div>
          </el-popover>
          <img v-if="row.scope.isCheckBalance" class="reload" src="@/assets/images/system/reload.png" @click="reload(row.scope)">
        </template>
        <template slot="opt" slot-scope="row">
          <div class="table-opt">
            <el-button
              v-show="newUpdate"
              :v-permissson="newUpdate"
              type="text"
              @click="updateRow(row.scope)"
            >修改</el-button
            >
            <el-button
              v-show="active === 1"
              v-permissson="'detail_a'"
              type="text"
              @click="billAccount(row.scope)"
            >价格详情</el-button
            >
            <el-button
              v-show="active === 3"
              v-permissson="'detail_c'"
              type="text"
              @click="detailRow(row.scope)"
            >详情</el-button
            >
            <el-button
              v-show="active === 1"
              v-permissson="'Subclients'"
              type="text"
              @click="subClients(row.scope)"
            >下属客户</el-button
            >
          </div>
        </template>
      </BaseTable>
    </div>
    <add-fuel-dialog
      :dialogVisible="dialogVisible"
      :supplyService="supplyService"
      :editData="editData"
      @ok="ok"
      @cancel="cancel"
    ></add-fuel-dialog>
    <add-bill-account-dialog
      :dialogVisible="dialogVisible2"
      :supplyService="supplyService"
      :title="title"
      :editData="editData"
      @ok="ok2"
      @cancel="cancel2"
    >
    </add-bill-account-dialog>
    <bill-setting-detail-dialog
      :dialogVisible="dialogVisible3"
      :expressAccountId="editData.id"
      @ok="ok3"
      @cancel="cancel3"
    >
    </bill-setting-detail-dialog>
    <SubClientsDialog
      :dialogVisible="dialogVisible4"
      :editData="editData"
      @cancel="dialogVisible4 = false"
      @ok="dialogVisible4 = false"
    >
    </SubClientsDialog>
    <AccountTempalteDialog
      :dialogVisible="dialogVisible5"
      :editData="editData"
      :accountTemTitle="accountTemTitle"
      @cancel="dialogVisible5 = false"
      @ok="ok4"
    >
    </AccountTempalteDialog>
  </div>
</template>

<script>
const BaseTable = () => import('@/components/table/BaseTable')
import config from '@/utils/config'
import {
  getExpressAccountPageList,
  getFuelRatePageList,
  getExpressSupplierList,
  getExpressAccountTempaltePage,
  updateExpressSupplierBalance
} from '@/api/system'
import { mapGetters } from 'vuex'
import { permissions } from '@/utils/common'
import AddFuelDialog from './components/AddFuelDialog.vue'
import AddBillAccountDialog from './components/AddBillAccountDialog.vue'
import BillSettingDetailDialog from './components/BillSettingDetailDialog.vue'
import SubClientsDialog from './components/SubClientsDialog.vue'
import AccountTempalteDialog from './components/AccountTempalteDialog.vue'
const BaseFilter = () => import('@/components/filter/BaseFilter')
import dayjs from 'dayjs'
import outDateImg from '@/assets/images/system/outDate.png'
import { myMixins } from '@/mixins'
export default {
  name: 'BillSetting',
  components: {
    BaseTable,
    BaseFilter,
    AddFuelDialog,
    AddBillAccountDialog,
    BillSettingDetailDialog,
    SubClientsDialog,
    AccountTempalteDialog
  },
  filters: {
    formarAmount(v, currency) {
      return v === null ? 0 : v + currency
    }
  },
  mixins: [myMixins],
  data() {
    return {
      pagerConfig: {
        pageIndex: 1,
        pageSize: 20
      },
      loading: false,
      tableData: {
        fileds: [
          {
            filed: 'accountNumber',
            label: '账号',
            width: '150',
            fixed: 'left',
            isSort: true
          },
          {
            filed: 'quoteType',
            label: '分类',
            width: '120',
            formatter: (row) => {
              if (row.quoteType) {
                const obj = {
                  'Domestic': '本土快递',
                  'Truck': '卡派',
                  'International': '国际快递'
                }
                return obj[row.quoteType]
              } else {
                return ''
              }
            },
            isSort: true
          },
          {
            filed: 'serviceProviderName',
            label: '快递服务商',
            width: '180',
            isSort: true
          },
          {
            filed: 'serviceTypeName',
            label: '服务类型',
            width: '180',
            isSort: true
          },
          {
            filed: 'currentAmount',
            label: '账号余额',
            width: '220',
            isCustom: true
          },
          {
            filed: 'shipmentMaxPcs',
            label: '单票最高件数',
            width: '120',
            isSort: true
          },
          {
            filed: 'vFlag',
            label: '材积除数',
            width: '100',
            isSort: true
          },
          {
            filed: 'dimensionUnit',
            label: '材积单位',
            width: '100',
            formatter: (row) => {
              const item = config.dimensionOptions.find(
                (a) => a.value === row.dimensionUnit
              )
              return item && item.label
            },
            isSort: true
          },
          {
            filed: 'weightUnit',
            label: '重量单位',
            width: '100',
            formatter: (row) => {
              let str = ''
              switch (row.weightUnit) {
                case 0:
                  str = '英镑'
                  break
                case 1:
                  str = '千克'
                  break
              }
              return str
            },
            isSort: true
          },
          {
            filed: 'calculateType',
            label: '计算方式',
            width: '100',
            formatter: (row) => {
              const item = config.calculateOptions.find(
                (a) => a.value === row.calculateType
              )
              return item && item.label
            },
            isSort: true
          },
          {
            filed: 'usageRate',
            label: '账号使用费',
            width: '120',
            isSort: true
          },
          {
            filed: 'isActived',
            label: '状态',
            width: '80',
            isCustom: true,
            isSort: true
          },
          {
            filed: 'supplierName',
            label: '账号供应商',
            width: '160'
          },
          {
            filed: 'apiSource',
            label: '对接来源',
            width: '160',
            formatter: (row) => {
              let str = ''
              switch (row.apiSource) {
                case 0:
                  str = '官方'
                  break
                case 1:
                  str = '第三方'
                  break
              }
              return str
            },
            isSort: true
          },
          {
            filed: 'isWeightLimit',
            label: '是否限重',
            width: '160',
            formatter: (row) => {
              return row.isWeightLimit ? '是' : '否'
            }
          },
          {
            filed: 'isPriceLimit',
            label: '是否低消',
            width: '160',
            formatter: (row) => {
              return row.isWeightLimit ? '是' : '否'
            }
          },
          {
            filed: 'opt',
            label: '操作',
            width: '220',
            fixed: 'right',
            isCustom: true
          }
        ],
        columns: [],
        total: 0
      },
      tableData1: {
        fileds: [
          {
            filed: 'serviceProviderName',
            label: '快递服务商',
            isCustom: true,
            isSort: true
          },
          {
            filed: 'countryId',
            label: '国家',
            formatter: (row) => {
              let str = ''
              if (this.addressDropOption && row.countryId) {
                const item = this.addressDropOption.find(
                  (a) => a.value === row.countryId
                )
                str = item.label
              }
              return str
            },
            isSort: true
          },
          {
            filed: 'rateValue',
            label: '燃油费率',
            isSort: true
          },
          {
            filed: 'validFrom',
            label: '开始日期',
            formatter: (row) =>
              row.validFrom
                ? row.validFrom.slice(0, row.validFrom.length - 9)
                : '',
            isSort: true
          },
          {
            filed: 'validTo',
            label: '结束日期',
            formatter: (row) =>
              row.validTo ? row.validTo.slice(0, row.validTo.length - 9) : '',
            isSort: true
          },
          {
            filed: 'remark',
            label: '备注'
          },
          {
            filed: 'opt',
            label: '操作',
            width: '160',
            fixed: 'right',
            isCustom: true
          }
        ],
        columns: [],
        total: 0
      },
      tableData2: {
        fileds: [
          {
            filed: 'templateName',
            label: '模板名称',
            width: 600
          },
          {
            filed: 'createdOn',
            label: '创建日期',
            formatter: (row) =>
              row.createdOn ? row.createdOn.slice(0, row.createdOn.length - 3) : ''
          },
          {
            filed: 'createdName',
            label: '创建人'
          },
          {
            filed: 'updatedOn',
            label: '修改日期',
            formatter: (row) =>
              row.updatedOn ? row.updatedOn.slice(0, row.updatedOn.length - 3) : ''
          },
          {
            filed: 'updatedName',
            label: '修改人'
          },
          {
            filed: 'opt',
            label: '操作',
            width: '160',
            fixed: 'right',
            isCustom: true
          }
        ],
        columns: [],
        total: 0
      },
      height: 0,
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false,
      dialogVisible5: false,
      editData: {},
      active: 0,
      activeCode: '',
      filterList: {
        expressSupplierId: '',
        countryId: '',
        search: '',
        quoteType: ''
      },
      isActive: false,
      esOptions: [],
      supplyService: [],
      title: '新增',
      sortable: {
        sortField: undefined,
        sortBy: undefined
      },
      accountTemTitle: '新增模板'
    }
  },
  computed: {
    ...mapGetters(['addressDropOption']),
    newAdd() {
      const menuList = this.$store.getters.initRouter
      const menu = permissions(this.$route.meta.code, menuList)
      const presentTab = menu.children.find((a) => a.code === this.activeCode)
      const code = presentTab?.children?.find((a) => a.nameCH === '新增')?.code
      return code || ''
    },
    newUpdate() {
      const menuList = this.$store.getters.initRouter
      const menu = permissions(this.$route.meta.code, menuList)
      const presentTab = menu.children.find((a) => a.code === this.activeCode)
      const code = presentTab?.children?.find((a) => a.nameCH === '修改')?.code
      return code || ''
    },
    tableTotalData() {
      let obj = {}
      switch (this.active) {
        case 1:
          obj = this.tableData
          break
        case 2:
          obj = this.tableData1
          break
        case 3:
          obj = this.tableData2
          break
      }
      return obj
    }
  },
  created() {
    const menuList = this.$store.getters.initRouter
    const menu = permissions(this.$route.meta.code, menuList)
    this.activeCode = menu.children[0].code
    const codeLits = ['ZDZH', 'RYSZ', 'KHZHMB']
    this.active = codeLits.indexOf(menu.children[0].code) + 1
    if (this.active === 1) {
      this.getList(this.pagerConfig)
    } else if (this.active === 2) {
      this.getFuelList(this.pagerConfig)
    } else {
      this.getAccountList(this.pagerConfig)
    }
    this.getExpressList()
  },
  mounted() {
    this.height = document.querySelector('.data').offsetHeight - 43
    if (!this.addressDropOption || this.addressDropOption.length === 0) {
      this.$store.dispatch('getAddress')
    }
  },
  methods: {
    async getList(params) {
      this.loading = true
      try {
        params = {
          ...params,
          queryParam: {
            ...this.sortable,
            ...this.formatterFilter()
          }
        }
        const res = await getExpressAccountPageList(params)
        if (res.success) {
          this.loading = false
          this.tableData.total = res.data.total
          this.tableData.columns = res.data.list
        } else {
          this.loading = false
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        this.loading = false
        console.error(error)
      }
    },
    async getFuelList(params) {
      this.loading = true
      try {
        params = {
          ...params,
          queryParam: {
            ...this.sortable,
            ...this.formatterFilter()
          }
        }
        const res = await getFuelRatePageList(params)
        if (res.success) {
          this.loading = false
          this.tableData1.total = res.data.total
          this.tableData1.columns = res.data.list
        } else {
          this.loading = false
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        this.loading = false
        console.error(error)
      }
    },
    async getAccountList(params) {
      this.loading = true
      try {
        params = {
          ...params,
          queryParam: {
            ...this.sortable
          }
        }
        const res = await getExpressAccountTempaltePage(params)
        if (res.success) {
          this.loading = false
          this.tableData2.total = res.data.total
          this.tableData2.columns = res.data.list
        } else {
          this.loading = false
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        this.loading = false
        console.error(error)
      }
    },
    async getExpressList() {
      try {
        const res = await getExpressSupplierList()
        if (res.success) {
          const arr = []
          res.data &&
            res.data.map((item) => {
              arr.push({
                label: item.name,
                value: item.id
              })
            })
          this.supplyService = res.data
          this.esOptions = arr
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    add() {
      this.editData = {}
      if (this.active === 1) {
        this.title = '新增'
        this.dialogVisible2 = true
      } else if (this.active === 2) {
        this.dialogVisible = true
      } else {
        this.accountTemTitle = '新增模板'
        this.dialogVisible5 = true
      }
    },
    handlePageChange(pager) {
      const params = {
        ...pager
      }
      this.pagerConfig = params
      if (this.active === 1) {
        this.getList(params)
      } else if (this.active === 2) {
        this.getFuelList(params)
      } else {
        this.getAccountList(params)
      }
    },
    sortChange(orderObj) {
      if (orderObj.order) {
        this.sortable = {
          sortField: orderObj.prop,
          sortBy: orderObj.order === 'asc' ? 0 : 1
        }
      } else {
        this.sortable = {}
      }
      if (this.active === 1) {
        this.getList(this.pagerConfig)
      } else if (this.active === 2) {
        this.getFuelList(this.pagerConfig)
      }
    },
    updateRow(row) {
      this.editData = row
      if (this.active === 1) {
        this.title = '修改'
        this.dialogVisible2 = true
      } else if (this.active === 2) {
        this.dialogVisible = true
      } else {
        this.accountTemTitle = '修改模板'
        this.dialogVisible5 = true
      }
    },
    ok() {
      this.cancel()
      // this.pagerConfig = {
      //   pageIndex: 1,
      //   pageSize: this.pagerConfig.pageSize
      // }
      // const params = {
      //   pageIndex: 1,
      //   pageSize: this.pagerConfig.pageSize
      // }
      this.getFuelList(this.pagerConfig)
    },
    cancel() {
      this.editData = {}
      this.dialogVisible = false
    },
    ok2() {
      this.cancel2()
      // this.pagerConfig = {
      //   pageIndex: 1,
      //   pageSize: this.pagerConfig.pageSize
      // }
      // const params = {
      //   pageIndex: 1,
      //   pageSize: this.pagerConfig.pageSize
      // }
      this.getList(this.pagerConfig)
    },
    cancel2() {
      this.editData = {}
      this.dialogVisible2 = false
    },
    changeType(type, code) {
      this.active = type
      this.activeCode = code
      const params = {
        pageIndex: 1,
        pageSize: this.pagerConfig.pageSize
      }
      this.pagerConfig = params
      this.reset()
      if (type === 1) {
        this.getList(params)
      } else if (type === 2) {
        this.getFuelList(params)
      } else {
        this.getAccountList(params)
      }
    },
    sendFilter() {
      if (
        this.filterList.expressSupplierId !== '' ||
        this.filterList.search !== '' ||
        this.filterList.quoteType !== '' ||
        this.filterList.countryId !== ''
      ) {
        this.isActive = true
      } else {
        this.isActive = false
      }
      const params = {
        pageIndex: 1,
        pageSize: 20
      }
      this.pagerConfig = params
      if (this.active === 1) {
        this.getList(params)
      } else {
        this.getFuelList(params)
      }
    },
    reset() {
      this.filterList = {
        expressSupplierId: '',
        countryId: '',
        quoteType: '',
        search: ''
      }
      this.isActive = false
    },
    formatterFilter() {
      return {
        expressSupplierId:
          this.filterList.expressSupplierId !== ''
            ? this.filterList.expressSupplierId
            : undefined,
        countryId:
          this.filterList.countryId !== ''
            ? this.filterList.countryId
            : undefined,
        quoteType:
          this.filterList.quoteType !== ''
            ? this.filterList.quoteType
            : undefined,
        search:
          this.filterList.search !== '' ? this.filterList.search : undefined
      }
    },
    billAccount(row) {
      this.editData = row
      this.dialogVisible3 = true
    },
    subClients(row) {
      this.editData = row
      this.dialogVisible4 = true
    },
    ok3() {
      this.cancel3()
      this.getList(this.pagerConfig)
    },
    cancel3() {
      this.editData = {}
      this.dialogVisible3 = false
    },
    isOutDate(date) {
      if (dayjs().isAfter(dayjs(date))) {
        return outDateImg
      }
    },
    ok4() {
      const params = {
        pageIndex: 1,
        pageSize: 20
      }
      this.pagerConfig = params
      this.getAccountList(params)
      this.dialogVisible5 = false
    },
    detailRow(row) {
      this.accountTemTitle = '详情'
      this.editData = row
      this.dialogVisible5 = true
    },
    async reload(row) {
      try {
        const res = await updateExpressSupplierBalance({ expressAccountId: row.id })
        if (res.success) {
          const { creditAmount, currentAmount, currency, balanceUpdateOn } = res.data
          this.$message.success('刷新成功')
          row.creditAmount = creditAmount
          row.currentAmount = currentAmount
          row.currency = currency
          row.balanceUpdateOn = balanceUpdateOn
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.view-contanier {
  width: 100%;
  padding: 0 10px;
  height: 100%;
  background: #fff;
  box-sizing: border-box;
  overflow: auto;
  .opt {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .tabItem {
      padding: 20px;
      width: 42px;
      height: 19px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
      cursor: pointer;
    }
    .active {
      color: #2395f3;
    }
  }
  .btn {
    width: 80px;
    height: 30px;
    background: #2395f3;
    border-radius: 4px;
    opacity: 1;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
    margin-right: 10px;
  }
  .icon {
    font-size: 20px;
    vertical-align: text-top;
  }
  .data {
    width: 100%;
    height: calc(100% - 50px);
    & .fTable {
      border-top: 1px solid #ebeef5;
      box-sizing: border-box;
      /deep/ .cell {
        position: relative;
      }
    }
    .outDate {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .circle {
    width: 6px;
    height: 6px;
    opacity: 1;
    display: inline-block;
    border-radius: 50%;
    margin-right: 6px;
  }
  .table-opt:deep(.el-button) {
    padding: 0;
  }
  .amount-mark {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 170px;
    height: 20px;
    background: rgba(255, 116, 68, .1);
    border-radius: 4px 4px 4px 4px;
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #FF7444;
    padding-left: 6px;
    box-sizing: border-box;
    & + .amount-mark {
      margin-top: 2px;
    }
  }
  .reload {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
  .status {
    width: 28px;
    height: 19px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #2395f3;
    line-height: 20px;
    margin-left: px;
  }
}
</style>
<style lang="less">
.current_box {
  width: 300px;
  height: 120px;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #EEEEEE;
  border-bottom: 0;
  margin: 0 auto;
  box-sizing: border-box;
  .current-items {
    display: flex;
    align-items: center;
    height: 40px;
    border-bottom: 1px solid #EEEEEE;
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      &:nth-child(1) {
        width: 40%;
      }
      &:nth-child(2) {
        width: 60%;
      }
      & + span {
        border-left: 1px solid #EEEEEE;
      }
    }
  }
}
</style>
