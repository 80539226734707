<template>
  <el-dialog
    v-drag
    :title="editData.id ? '修改' : '新增'"
    :visible.sync="dialogVisible"
    width="800px"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="content">
      <el-form ref="form" class="form" :model="form" :rules="rules">
        <div class="form-item">
          <label class="lable"><span class="red">* </span>快递服务商</label>
          <el-form-item prop="expressSupplierId">
            <el-select
              v-model="form.expressSupplierId"
              :disabled="!!form.id"
              style="width: 100%"
              placeholder="请选择快递服务商"
            >
              <el-option
                v-for="item in expressSupplierOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <!-- <div class="form-item">
          <label class="lable"><span class="red"> </span>服务类型</label>
          <el-form-item prop="expressSupplierServiceId">
            <el-select
              v-model="form.expressSupplierServiceId"
              :disabled="!!form.id"
              style="width: 100%"
              placeholder="请选择服务类型"
            >
              <el-option
                v-for="item in esServiceIdOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div> -->
        <div class="form-item">
          <label class="lable">国家</label>
          <el-form-item prop="countryId">
            <el-select
              v-model="form.countryId"
              :disabled="!!form.id"
              style="width: 100%"
              placeholder="请选择服务类型"
            >
              <el-option
                v-for="item in addressDropOption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>燃油费率</label>
          <el-form-item prop="rateValue">
            <el-input-number
              v-model="form.rateValue"
              :disabled="!!form.id"
              style="width: 100%"
              controls-position="right"
              :min="0.0"
              :precision="3"
              :step="0.1"
            ></el-input-number>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>开始时间</label>
          <el-form-item prop="validFrom">
            <el-date-picker
              v-model="form.validFrom"
              :disabled="!!form.id"
              style="width: 100%"
              value-format="yyyy-MM-dd"
              align="center"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>截止时间</label>
          <el-form-item prop="validTo">
            <el-date-picker
              v-model="form.validTo"
              style="width: 100%"
              value-format="yyyy-MM-dd"
              align="center"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
        </div>
        <div class="form-item" style="width: 100%; margin-left: 0;">
          <label class="lable">备注</label>
          <el-form-item prop="remark">
            <el-input
              v-model="form.remark"
              placeholder="请输入备注内容"
            ></el-input>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div class="ok flex_c_c" @click="ok('form')">确 定</div>
    </span>
  </el-dialog>
</template>

<script>
import { insertFuelRate, updateFuelRate } from '@/api/system'
import { mapGetters } from 'vuex'
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    supplyService: {
      type: Array,
      default: function() {
        return []
      }
    },
    editData: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      form: {
        expressSupplierId: '',
        countryId: '',
        rateValue: '',
        validFrom: '',
        validTo: '',
        remark: ''
      },
      rules: {
        expressSupplierId: [
          { required: true, message: '请选择快递服务商', trigger: 'blur' }
        ],
        // expressSupplierServiceId: [
        //   { required: true, message: '请选择服务类型', trigger: 'blur' }
        // ],
        rateValue: [
          { required: true, message: '请输入燃油费率', trigger: 'blur' }
        ],
        validFrom: [
          { required: true, message: '请输入开始时间', trigger: 'blur' }
        ],
        validTo: [
          { required: true, message: '请输入截止时间', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['addressDropOption']),
    expressSupplierOptions: function() {
      const arr = this.supplyService.map((item) => {
        return {
          label: item.name,
          value: item.id
        }
      })
      return arr || []
    },
    esServiceIdOptions: function() {
      const service = this.supplyService.find((item) => {
        return item.id === this.form.expressSupplierId
      })
      const arr =
        service &&
        service.serviceTypeList.map((item) => {
          return {
            label: item.name,
            value: item.id
          }
        })
      return arr || []
    }
  },
  watch: {
    dialogVisible: function() {
      if (this.editData.id) {
        this.form = {
          ...this.editData
        }
      } else {
        this.form = {
          esId: '',
          esServiceId: '',
          countryId: '',
          rateValue: '',
          validFrom: '',
          validTo: '',
          remark: ''
        }
      }
    }
  },
  methods: {
    cancel() {
      this.$refs['form']?.resetFields()
      this.$emit('cancel')
    },
    ok(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!this.editData.id) {
            insertFuelRate(this.form).then((res) => {
              if (res.success) {
                this.$message.success('新增成功')
                this.$refs['form']?.resetFields()
                this.$emit('ok')
              } else {
                this.$message.error(res.errorMessage)
              }
            })
          } else {
            updateFuelRate(this.form).then((res) => {
              if (res.success) {
                this.$message.success('修改成功')
                this.$refs['form']?.resetFields()
                this.$emit('ok')
              } else {
                this.$message.error(res.errorMessage)
              }
            })
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: calc(100% - 120px);
  height: auto;
  margin: 0 auto;
  text-align: left;
  .form {
    display: flex;
    width: 100%;
    height: auto;
    flex-wrap: wrap;
  }
  .form-item {
    width: calc(50% - 20px);
    position: relative;
    .lable {
      width: 100%;
      height: 19px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      margin-bottom: 5px;
    }
    .red {
      color: rgba(255, 116, 68, 1);
    }
    &:nth-child(2n) {
      margin-left: 40px;
    }
  }
}
/deep/ .el-dialog__body {
  padding: 20px 20px;
}
.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #EEEEEE;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>
