var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: "复制账号分区",
        visible: _vm.dialogVisible,
        width: "520px",
        "show-close": true,
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.cancel,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "form",
              attrs: { model: _vm.form, rules: _vm.rules },
            },
            [
              _c(
                "div",
                { staticClass: "panle" },
                [
                  _c("p", { staticClass: "lable" }, [
                    _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                    _vm._v("选择账号"),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "copyExpressAccountId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "选择账号" },
                          on: { change: _vm.change },
                          model: {
                            value: _vm.form.copyExpressAccountId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "copyExpressAccountId", $$v)
                            },
                            expression: "form.copyExpressAccountId",
                          },
                        },
                        _vm._l(_vm.accountOption, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "panle" },
                [
                  _c("p", { staticClass: "lable" }, [
                    _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                    _vm._v("账号分区"),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "expressAccountZoneId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { multiple: "", placeholder: "账号分区" },
                          model: {
                            value: _vm.form.expressAccountZoneId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "expressAccountZoneId", $$v)
                            },
                            expression: "form.expressAccountZoneId",
                          },
                        },
                        _vm._l(_vm.zoomOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "span",
        { staticClass: "footers", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "div",
            { staticClass: "cancel flex_c_c", on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "div",
            {
              staticClass: "ok flex_c_c",
              on: {
                click: function ($event) {
                  return _vm.ok("form")
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }