<template>
  <div class="content">
    <div class="add">
      <div class="item" @click="add">
        <i class="el-icon-circle-plus-outline" />
        <span>新增</span>
      </div>
    </div>
    <BaseTable
      :isPager="false"
      :showTooltip="false"
      :height="240"
      :border="true"
      :tableData="tableData"
    >
      <template slot="isActived" slot-scope="row">
        <div
          class="circle"
          :style="{
            'background-color': row.scope.isActived ? ' #35BA83' : '#FE453D',
          }"
        ></div>
        {{ row.scope.isActived ? "启用" : "停用" }}
      </template>
      <template slot="opt" slot-scope="row">
        <i class="el-icon-delete" style="color:#2395F3" @click="deleteRow(row.scope)" />
      </template>
    </BaseTable>
    <add-account-restrictions-dialog
      :dialogVisible="dialogVisible"
      @ok="ok"
      @cancel="cancel">
    </add-account-restrictions-dialog>
  </div>
</template>
<script>
import AddAccountRestrictionsDialog from './AddAccountRestrictionsDialog.vue'
const BaseTable = () => import('@/components/table/BaseTable')
import config from '@/utils/config'

export default {
  components: { BaseTable, AddAccountRestrictionsDialog },
  props: {
    editData: {
        type: Array,
        default: function() {
            return []
        }
    }
  },
  data() {
    return {
      tableData: {
        fileds: [
          {
            filed: 'startTime',
            label: '开始时间',
            width: '120',
            resizable: true
          },
          {
            filed: 'endTime',
            label: '开始时间',
            width: '120',
            resizable: true
          },
          {
            filed: 'limitType',
            label: '限制类型',
            width: '120',
            formatter: (row) => {
              const item = config.limitTypeOptions.find(a => a.value === row.limitType)
              return item ? item.value : ''
            },
            resizable: true
          },
          {
            filed: 'limitDaysPeriod',
            label: '限制周期(天)',
            width: '120',
            resizable: true
          },
          {
            filed: 'limitNumbers',
            label: '检查基数',
            width: '120',
            resizable: true
          },
          {
            filed: 'unitsCode',
            label: '基数单位',
            width: '120',
            resizable: true
          },
          {
            filed: 'calculateLevel',
            label: '优先级',
            width: '120',
            resizable: true
          },
          {
            filed: 'isActived',
            label: '状态',
            width: '',
            isCustom: true,
            resizable: true
          },
          {
            filed: 'opt',
            label: '操作',
            width: '',
            isCustom: true,
            resizable: true
          }
        ],
        columns: []
      },
      dialogVisible: false
    }
  },
  watch: {
    editData: {
        handler: function(data) {
            this.tableData.columns = data
        },
        deep: true
    }
  },
  methods: {
    ok(row) {
        this.tableData.columns.push(row)
        this.cancel()
    },
    deleteRow(row) {
        this.tableData.columns.splice(this.tableData.columns.indexOf(row), 1)
    },
    cancel() {
        this.dialogVisible = false
    },
    add() {
        this.dialogVisible = true
    },
    getData() {
        return this.tableData.columns
    }
  }
}
</script>
<style lang="less" scoped>
.add{
    width: 100%;
    height: 40px;
    margin: 20px 0;
    display: flex;
    flex-direction: row-reverse;
    .item {
        width: 100px;
        height: 40px;
        background: #35BA83;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
        span {
            margin-left: 5px;
        }
    }
}
</style>
