<template>
  <el-dialog
    v-drag
    :title="type ? '修改' : '新增'"
    :visible.sync="dialogVisible"
    width="800px"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="content">
      <el-form ref="form" class="form" :model="form" :rules="rules">
        <div class="form-item">
          <label class="lable"><span class="red">* </span>费用项</label>
          <el-form-item prop="code">
            <el-select
              v-model="form.code"
              style="width: 100%"
              placeholder="请选择费用项"
            >
              <el-option
                v-for="(item, $index) in feeOptions"
                :key="$index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span> 费用</label>
          <el-form-item prop="price">
            <el-input-number
              v-model="form.price"
              style="width: 100%"
              controls-position="right"
              :min="0"
              :precision="2"
            ></el-input-number>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>成本</label>
          <el-form-item prop="cost">
            <el-input-number
              v-model="form.cost"
              style="width: 100%"
              controls-position="right"
              :min="0"
              :precision="2"
            ></el-input-number>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>币制</label>
          <el-form-item prop="currency">
            <base-select-common
              v-model="form.currency"
            ></base-select-common>
            <!-- <el-input-number
              v-model="form.limitDaysPeriod"
              style="width: 100%"
              controls-position="right"
              :min="0"
            ></el-input-number> -->
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>计算方式</label>
          <el-form-item prop="calculateType">
            <el-select
              v-model="form.calculateType"
              style="width: 100%"
              placeholder="请选择优先级"
            >
              <el-option
                v-for="(item, $index) in calculateTypelOptions"
                :key="$index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>计算维度</label>
          <el-form-item prop="caculateDimension">
            <el-select
              v-model="form.caculateDimension"
              style="width: 100%"
              placeholder="请选择优先级"
            >
              <el-option
                v-for="(item, $index) in caculateDimensionOptions"
                :key="$index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>状态</label>
          <el-form-item prop="isActived">
            <el-switch
              v-model="form.isActived"
              active-color="#2395F3"
              inactive-color="#f1f1f1"
            >
            </el-switch>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div class="ok flex_c_c" @click="ok('form')">确 定</div>
    </span>
  </el-dialog>
</template>

<script>
import config from '@/utils/config'
import BaseSelectCommon from '@/components/base/BaseSelectCommon.vue'
import { getFeeNamePageList } from '@/api/fee'
export default {
  components: { BaseSelectCommon },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    editData: {
      type: Object,
      default: function() {
        return {}
      }
    },
    type: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      form: {
        code: '', // 费用代码
        name: '', // 费用名称
        calculateType: 1, // 计算方式
        caculateDimension: '', // 计算维度
        isActived: true,
        price: '', // 费用
        cost: '', // 成本
        currency: '' // 币制
      },
      rules: {
        code: [
          {
            required: true,
            message: '请选择费用代码',
            trigger: 'change'
          }
        ],
        calculateType: [
          {
            required: true,
            message: '请选择计算方式',
            trigger: 'change'
          }
        ],
        caculateDimension: [
          {
            required: true,
            message: '请选择计算维度',
            trigger: 'change'
          }
        ],
        price: [
          {
            required: true,
            message: '请输入费用',
            trigger: 'blur'
          }
        ],
        cost: [
          {
            required: true,
            message: '请输入成本',
            trigger: 'blur'
          }
        ],
        currency: [
          {
            required: true,
            message: '请选择币制',
            trigger: 'change'
          }
        ],
        endTime: [
          { required: true, message: '请输入截止时间', trigger: 'change' }
        ]
      },
      caculateDimensionOptions: config.caculateDimensionOptions,
      calculateTypelOptions: config.calculateTypelOptions,
      feeOptions: []
    }
  },
  watch: {
    dialogVisible: function(val) {
      if (val) {
        this.getList()
        if (this.editData.code) {
          this.$refs['form']?.resetFields()
          this.form = {
            ...this.editData,
            code: this.editData.code + '-' + this.editData.name
          }
        } else {
          this.form = {
            code: '', // 费用代码
            name: '', // 费用名称
            calculateType: 1, // 计算方式
            caculateDimension: '', // 计算维度
            isActived: true,
            price: '', // 费用
            cost: '', // 成本
            currency: '' // 币制
          }
          this.$refs['form']?.resetFields()
        }
      }
    }
  },
  methods: {
    async getList() {
       try {
         const res = await getFeeNamePageList({
            pageIndex: 1,
            pageSize: 1000,
            queryParam: {
              isVisible: false
            }
        })
        if (res.success) {
            const arr = res.data.list.filter(a => a.feeTypeId === 3)
            .map(item => {
                return {
                    value: `${item.code}-${item.name}`,
                    label: `${item.code}-${item.name}`
                }
            })
            this.feeOptions = arr
        } else {
          this.$message.error(res.errorMessage)
        }
       } catch (error) {
        console.log(error)
       }
    },
    cancel() {
      this.$refs['form'].resetFields()
      this.$emit('cancel')
    },
    ok(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit('ok', { ...this.form, code: this.form.code.split('-')[0], name: this.form.code.split('-')[1] })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: calc(100% - 80px);
  height: auto;
  margin: 0 auto;
  text-align: left;
  .form {
    display: flex;
    width: 100%;
    height: auto;
    flex-wrap: wrap;
  }
  .form-item {
    width: calc(50% - 20px);
    position: relative;
    .lable {
      width: 100%;
      height: 19px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      margin-bottom: 5px;
    }
    .red {
      color: rgba(255, 116, 68, 1);
    }
    &:nth-child(2n) {
      margin-left: 40px;
    }
  }
}
/deep/ .el-dialog__body {
  padding: 20px 20px;
}
.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #EEEEEE;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>
