<template>
  <el-dialog
    v-drag
    title="导入价格"
    :visible.sync="dialogVisible"
    width="520px"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="content">
      <base-upload ref="baseUpload" :action="`/backEnd/api/${config.supplyCode}/MakingSetting/importExpressAccountPrice`" :uploadData="uploadData" :uploadType="1" class="upload" @success="uploadSuccess"></base-upload>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div class="ok flex_c_c" @click="ok('form')">确 定</div>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import config from '@/utils/config'
const BaseUpload = () => import('../../../components/base/BaseUpload.vue')
export default {
  components: { BaseUpload },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    expressAccountId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      uploadResData: '',
      config
    }
  },
  computed: {
    ...mapGetters(['addressDropOption']),
    uploadData: function() {
        return {
          expressAccountId: this.expressAccountId
        }
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    ok() {
      this.$refs.baseUpload.init()
      this.$emit('ok', this.uploadResData)
    },
    uploadSuccess(data) {
      this.uploadResData = data
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: calc(100% - 120px);
  height: auto;
  margin: 0 auto;
  text-align: left;
  .red {
    color: #ff7444;
  }
  .lable {
    width: 100%;
    height: 19px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
    margin-top: 20px;
  }
  .upload {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 30px;
  }
}
/deep/ .el-dialog__body {
  padding: 0;
}
/deep/ .el-dialog__header {
  border-bottom: 1px solid #f7f7f7;
}
.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #EEEEEE;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>
