var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: "下属客户",
        visible: _vm.dialogVisible,
        width: "1160px",
        "show-close": true,
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.cancel,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "opt" }, [
          _c(
            "div",
            {
              staticClass: "copy flex_c_c",
              on: {
                click: function ($event) {
                  _vm.dialogVisible1 = true
                },
              },
            },
            [
              _c("img", {
                attrs: { src: require("@/assets/images/system/copy.png") },
              }),
              _vm._v("复制其他账号客户 "),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "add flex_c_c",
              on: {
                click: function ($event) {
                  _vm.dialogVisible2 = true
                },
              },
            },
            [
              _c("svg-icon", {
                attrs: { className: "icon", iconClass: "add" },
              }),
              _vm._v("新增 "),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "data" },
          [
            _c("BaseTable", {
              attrs: {
                height: 439,
                tableData: _vm.tableData,
                loading: _vm.loading,
                border: true,
                isPager: false,
              },
              scopedSlots: _vm._u([
                {
                  key: "opt",
                  fn: function (row) {
                    return [
                      _c(
                        "div",
                        { staticClass: "table-opt" },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-text": row.scope.caeActived
                                ? "启用"
                                : "停用",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.onChange(
                                  row.scope,
                                  row.scope.caeActived
                                )
                              },
                            },
                            model: {
                              value: row.scope.caeActived,
                              callback: function ($$v) {
                                _vm.$set(row.scope, "caeActived", $$v)
                              },
                              expression: "row.scope.caeActived",
                            },
                          }),
                          _c("img", {
                            staticClass: "delete",
                            attrs: {
                              title: "删除",
                              src: require("@/assets/images/const/delete.png"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deleteRow(row.scope)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _c(
        "span",
        { staticClass: "footers", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "div",
            { staticClass: "cancel flex_c_c", on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c("div", { staticClass: "ok flex_c_c", on: { click: _vm.ok } }, [
            _vm._v("确 定"),
          ]),
        ]
      ),
      _c("CopyOtherSubClientsDialog", {
        attrs: { dialogVisible: _vm.dialogVisible1, editData: _vm.editData },
        on: {
          cancel: function ($event) {
            _vm.dialogVisible1 = false
          },
          ok: _vm.copyOk,
        },
      }),
      _c("AddSubClientsDialog", {
        attrs: { dialogVisible: _vm.dialogVisible2, editData: _vm.editData },
        on: {
          cancel: function ($event) {
            _vm.dialogVisible2 = false
          },
          ok: _vm.addOk,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }