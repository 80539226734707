<template>
  <el-dialog
    v-drag
    title="下属客户"
    :visible.sync="dialogVisible"
    width="1160px"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="content">
      <div class="opt">
        <div class="copy flex_c_c" @click="dialogVisible1 = true">
          <img src="@/assets/images/system/copy.png">复制其他账号客户
        </div>
        <div class="add flex_c_c" @click="dialogVisible2 = true">
          <svg-icon className="icon" iconClass="add" />新增
        </div>
      </div>
      <div class="data">
        <BaseTable
          :height="439"
          :tableData="tableData"
          :loading="loading"
          :border="true"
          :isPager="false"
        >
          <template slot="opt" slot-scope="row">
            <div class="table-opt">
              <el-switch
                v-model="row.scope.caeActived"
                :active-text="row.scope.caeActived ? '启用' : '停用'"
                @change="onChange(row.scope, row.scope.caeActived)">
              </el-switch>
              <img class="delete" title="删除" src="@/assets/images/const/delete.png" @click="deleteRow(row.scope)">
            </div>
          </template>
        </BaseTable>
      </div>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div class="ok flex_c_c" @click="ok">确 定</div>
    </span>
    <CopyOtherSubClientsDialog
      :dialogVisible="dialogVisible1"
      :editData="editData"
      @cancel="dialogVisible1 = false"
      @ok="copyOk"
    ></CopyOtherSubClientsDialog>
    <AddSubClientsDialog
      :dialogVisible="dialogVisible2"
      :editData="editData"
      @cancel="dialogVisible2 = false"
      @ok="addOk"
    ></AddSubClientsDialog>
  </el-dialog>
</template>

<script>
const BaseTable = () => import('@/components/table/BaseTable')
import AddSubClientsDialog from './AddSubClientsDialog'
import CopyOtherSubClientsDialog from './CopyOtherSubClientsDialog'
import { getExpressAccountCompanyList, deleteCEA, switchCompanyExpressAccount } from '@/api/system'
export default {
  components: { BaseTable, CopyOtherSubClientsDialog, AddSubClientsDialog },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    editData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      loading: false,
      tableData: {
        fileds: [
          {
            filed: 'identityCode',
            label: '客户编码',
            width: 180,
            resizable: true
          },
          {
            filed: 'companyName',
            label: '客户名称',
            width: 340,
            resizable: true
          },
          {
            filed: 'deliveryRate',
            label: '派送折扣',
            width: 100,
            resizable: true
          },
          {
            filed: 'salesName',
            label: '销售员',
            width: 100,
            resizable: true
          },
          {
            filed: 'currency',
            label: '结算币制',
            width: 100,
            resizable: true
          },
          {
            filed: 'areaValue',
            label: '所属区域',
            width: 120,
            resizable: true
          },
          {
            filed: 'opt',
            label: '操作',
            width: '',
            isCustom: true,
            resizable: true
          }
        ],
        columns: []
      },
      dialogVisible1: false,
      dialogVisible2: false
    }
  },
  watch: {
    dialogVisible(val) {
      if (val && this.editData.id) {
        this.getList()
      }
    }
  },
  methods: {
    async getList() {
      this.loading = true
      try {
        const res = await getExpressAccountCompanyList({ expressAccountId: this.editData.id })
        if (res.success) {
          this.tableData.columns = res.data
          this.loading = false
        } else {
          this.$message.error(res.errorMessage)
          this.loading = false
        }
      } catch (error) {
        console.error(error)
      }
    },
    cancel() {
      this.$emit('cancel')
    },
    ok() {
      this.$emit('ok')
    },
    async deleteRow(row) {
      try {
        const res = await deleteCEA({ id: row.id })
        if (res.success) {
          this.getList()
          this.$message.success('删除成功')
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    addOk() {
      this.getList()
      this.dialogVisible2 = false
    },
    copyOk() {
      this.getList()
      this.dialogVisible1 = false
    },
    async onChange(params, type) {
      try {
        const res = await switchCompanyExpressAccount([params.id], type ? 1 : 0)
        if (res.success) {
          this.$message.success(res.errorMessage)
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: calc(100% - 40px);
  height: auto;
  margin: 0 auto;
  .opt {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;
    & div {
      height: 40px;
      border-radius: 4px 4px 4px 4px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
      cursor: pointer;
      img {
        width: 20px;
        height: 20px;
      }
      svg {
        font-size: 20px;
      }
    }
    .copy {
      width: 150px;
      background: #2395F3;
      margin-right: 10px;
    }
    .add {
      width: 80px;
      background: #35BA83;
    }
  }
  .data {
    width: 100%;
    height: auto;
    /deep/ .fTable .el-table {
      border-radius: 4px 4px 4px 4px;
      overflow: hidden;
      .fp-cell {
        border: 0;
      }
    }
    .delete {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
    .table-opt {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #EEEEEE;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 20px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>
