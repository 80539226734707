var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: "复制其他账号的下属客户",
        visible: _vm.dialogVisible,
        width: "1160px",
        "show-close": true,
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.cancel,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          { staticClass: "opt flex_c_c" },
          [
            _c(
              "el-select",
              {
                staticStyle: { width: "400px" },
                attrs: { filterable: "", placeholder: "输入要复制的账号" },
                on: { change: _vm.onChange },
                model: {
                  value: _vm.expressAccountId,
                  callback: function ($$v) {
                    _vm.expressAccountId = $$v
                  },
                  expression: "expressAccountId",
                },
              },
              _vm._l(_vm.accountOtions, function (item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.label, value: item.value },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "data" },
          [
            _c("BaseTable", {
              attrs: {
                height: 439,
                tableData: _vm.tableData,
                loading: _vm.loading,
                border: true,
                isPager: false,
              },
              on: { handleSelectionChange: _vm.handleSelectionChange },
              scopedSlots: _vm._u([
                {
                  key: "deliveryRate",
                  fn: function (row) {
                    return [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: row.scope.deliveryRate,
                          callback: function ($$v) {
                            _vm.$set(row.scope, "deliveryRate", $$v)
                          },
                          expression: "row.scope.deliveryRate",
                        },
                      }),
                    ]
                  },
                },
                {
                  key: "custom-total",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "select" }, [
                        _vm._v(" 已选 "),
                        _c("span", [_vm._v(_vm._s(_vm.selectionItem.length))]),
                        _vm._v(" 项 "),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _c(
        "span",
        { staticClass: "footers", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "div",
            { staticClass: "cancel flex_c_c", on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c("div", { staticClass: "ok flex_c_c", on: { click: _vm.ok } }, [
            _vm._v("确 定"),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }