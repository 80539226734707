<template>
  <el-dialog
    v-drag
    title="导入分区"
    :visible.sync="dialogVisible"
    width="520px"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="content">
      <div class="panle">
        <p class="lable"><span class="red">* </span>选择子类目</p>
        <el-select
          v-model="type"
          style="width: 100%"
          placeholder="分区子类目类型"
        >
          <el-option
            v-for="item in typeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="panle">
        <p class="lable"><span class="red">* </span>价格模板</p>
        <el-select
          v-model="tplId"
          style="width: 100%"
          placeholder="分区价格模板"
        >
          <el-option
            v-for="item in tplOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <!-- <div class="panle">
        <p class="lable">选择始发地</p>
        <el-cascader
          v-model="address"
          style="width: 100%"
          :options="addressDropOption"
        >
        </el-cascader>
      </div> -->
      <base-upload ref="baseUpload" :uploadData="uploadData" class="upload"></base-upload>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div class="ok flex_c_c" @click="ok('form')">确 定</div>
    </span>
  </el-dialog>
</template>

<script>
import config from '@/utils/config'
// import { mapGetters } from 'vuex'
const BaseUpload = () => import('@/components/base/BaseUpload.vue')
import { getExpressAccountPriceTemplatePageList } from '@/api/system'
export default {
  components: { BaseUpload },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    expressAccountId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      typeOptions: config.typeOptions,
      address: '',
      type: '',
      tplList: [],
      tplId: ''
    }
  },
  computed: {
    // ...mapGetters(['addressDropOption']),
    tplOption() {
      return this.tplList.map(item => {
        return {
          value: item.id,
          label: item.code
        }
      })
    },
    uploadData: function() {
      if (this.tplId) {
        // const tpl = this.tplList.find(a => a.id === this.tplId)
        return {
          expressAccountId: this.expressAccountId,
          type: this.type,
          // countryId: tpl.countryId,
          // originCityId: tpl.originCityId
          expressAccountPriceTemplateId: this.tplId
        }
      } else {
        return {
          expressAccountId: this.expressAccountId,
          type: this.type
        }
      }
    }
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.getList()
      }
    }
  },
  methods: {
    async getList() {
      try {
        this.loading = true
        const res = await getExpressAccountPriceTemplatePageList({
          pageIndex: 1,
          pageSize: 1000,
          queryParam: {
            expressAccountId: this.expressAccountId
          }
        })
        if (res.success) {
          this.tplList = res.data.list
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.log(error)
      }
    },
    cancel() {
      this.$emit('cancel')
    },
    ok() {
      this.$refs.baseUpload.init()
      this.$emit('ok')
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: calc(100% - 120px);
  height: auto;
  margin: 0 auto;
  text-align: left;
  .red {
    color: #ff7444;
  }
  .lable {
    width: 100%;
    height: 19px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
    margin-top: 20px;
  }
  .upload {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 30px;
  }
}
/deep/ .el-dialog__body {
  padding: 0;
}
/deep/ .el-dialog__header {
  border-bottom: 1px solid #f7f7f7;
}
.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #EEEEEE;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>
