<template>
  <el-dialog
    v-drag
    title="查看价格"
    :visible.sync="dialogVisible"
    width="1160px"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="content">
      <div class="tab">
        <BaseTab
          style="z-index: 1"
          :active="isActive"
          :tabItems="tabItems"
          :propWidth="490"
          @tabChange="tabChange"
        ></BaseTab>
      </div>
      <div class="view">
        <div class="search">
          <el-input v-show="isActive === 0" v-model="text" style="width: 240px;" placeholder="请输入分区" class="input-with-select">
            <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
          </el-input>
        </div>
        <base-table
          v-show="isActive === 0"
          :showTooltip="false"
          :height="360"
          :border="true"
          :tableData="tableData"
          :pageIndex="pagerConfig.pageIndex"
          :pageSize="pagerConfig.pageSize"
          :loading="loading"
          @handlePageChange="handlePageChange">
        </base-table>
        <base-table
          v-show="isActive === 1"
          :showTooltip="false"
          :isPager="false"
          :height="360"
          :border="true"
          :tableData="tableData1">
        </base-table>
      </div>
    </div>
    <!-- <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div class="ok flex_c_c" @click="ok('form')">确 定</div>
    </span> -->
  </el-dialog>
</template>

<script>
const BaseTab = () => import('@/components/tab/BaseTab.vue')
const BaseTable = () => import('@/components/table/BaseTable.vue')
import { mapGetters } from 'vuex'
import config from '@/utils/config'
import { getExpressAccountPricePageList, getExpressAccountOtherPriceList } from '@/api/system'
export default {
  components: { BaseTab, BaseTable },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    expressAccountPriceTemplateId: {
        type: Number,
        default: 0
    }
  },
  data() {
    return {
      tabItems: [
        {
          id: 0,
          name: '价格列表'
        },
        {
          id: 1,
          name: '附加费列表'
        }
      ],
      pagerConfig: {
        pageIndex: 1,
        pageSize: 20
      },
      loading: true,
      isActive: 0,
      tableData: {
        fileds: [
          {
            filed: 'zoneCode',
            label: '分区',
            width: '',
            resizable: true
          },
          {
            filed: 'cityId',
            label: '始发地',
            width: '',
            formatter: (row) => {
              let str = ''
              if (this.cityOptions) {
                const item = this.cityOptions.find(
                  (a) => a.value === row.cityId
                )
                if (item) {
                  str = item.label
                }
              }
              return str
            },
            resizable: true
          },
          {
            filed: 'weightFrom',
            label: '起始重量',
            width: '',
            resizable: true
          },
          {
            filed: 'weightTo',
            label: '终止重量',
            width: '',
            resizable: true
          },
          {
            filed: 'weightUnitCode',
            label: '重量单位',
            width: '',
            resizable: true
          },
          {
            filed: 'price',
            label: '价格',
            width: '',
            resizable: true
          },
          {
            filed: 'currency',
            label: '币制',
            width: '',
            resizable: true
          },
          {
            filed: 'createdName',
            label: '创建人',
            width: '',
            resizable: true
          },
          {
            filed: 'createdOn',
            label: '创建时间',
            width: '',
            formatter: (row) => {
              return row.createdOn.split(' ')[0]
            },
            resizable: true
          }
        ],
        columns: [],
        total: 0
      },
      tableData1: {
        fileds: [
          {
            filed: 'code',
            label: '费用项',
            width: '220',
            resizable: true
          },
          {
            filed: 'price',
            label: '费用',
            width: '100',
            resizable: true
          },
          {
            filed: 'cost',
            label: '成本',
            width: '100',
            resizable: true
          },
          {
            filed: 'currency',
            label: '币制',
            width: '80',
            resizable: true
          },
          {
            filed: 'calculateType',
            label: '计算方式',
            width: '110',
            formatter: (row) => {
              const item = config.calculateTypelOptions.find(
                (a) => a.value === row.calculateType
              )
              return item.label
            },
            resizable: true
          },
          {
            filed: 'caculateDimension',
            label: '计算维度',
            width: '110',
            formatter: (row) => {
              const item = config.caculateDimensionOptions.find(
                (a) => a.value === row.calculateType
              )
              return item.label
            },
            resizable: true
          },
          {
            filed: 'isActived',
            label: '状态',
            width: '',
            formatter: (row) => {
              return row.isActived ? '启用' : '停用'
            },
            resizable: true
          },
          {
            filed: 'createdName',
            label: '创建人',
            width: '',
            resizable: true
          },
          {
            filed: 'createdOn',
            label: '创建时间',
            width: '',
            formatter: (row) => {
              return row.createdOn.split(' ')[0]
            },
            resizable: true
          }
        ],
        columns: [],
        total: 0
      },
      text: ''
    }
  },
  computed: {
    ...mapGetters(['addressDropOption']),
        cityOptions: function() {
      const arr = []
      this.addressDropOption.map((country) => {
        country.children.map((province) => {
          province.children.map((city) => {
            arr.push(city)
          })
        })
      })
      return arr
    }
  },
  watch: {
    dialogVisible: function(val) {
        if (val) {
            this.getList(this.pagerConfig)
            this.getOtherList()
        }
    }
  },
  methods: {
    async getList(parmas) {
       try {
        this.loading = true
         const res = await getExpressAccountPricePageList({
            ...parmas,
            queryParam: {
                expressAccountPriceTemplateId: this.expressAccountPriceTemplateId,
                zoneCode: this.text
            }
        })
        if (res.success) {
          this.loading = false
          this.tableData.columns = res.data.list
          this.tableData.total = res.data.total
        } else {
          this.$message.error(res.errorMessage)
        }
       } catch (error) {
        console.log(error)
       }
    },
    search() {
      this.pagerConfig = {
        pageIndex: 1,
        pageSize: 20
      }
      this.getList({
        pageIndex: 1,
        pageSize: 20
      })
    },
    async getOtherList() {
       try {
         const res = await getExpressAccountOtherPriceList({
            expressAccountPriceTemplateId: this.expressAccountPriceTemplateId
        })
        if (res.success) {
          this.tableData1.columns = res.data
        } else {
          this.$message.error(res.errorMessage)
        }
       } catch (error) {
        console.log(error)
       }
    },
    handlePageChange(pager) {
      this.pagerConfig = {
        ...this.pagerConfig,
        ...pager
      }
      const params = {
        ...this.pagerConfig,
        ...pager
      }
      this.getList(params)
    },
    cancel() {
      this.isActive = 0
      this.$emit('cancel')
    },
    ok() {
      this.$emit('ok')
    },
    tabChange(item) {
      this.isActive = item.id
    },
    add() {}
  }
}
</script>

<style lang="less" scoped>
.content {
  height: auto;
  margin: 0 auto;
  .tab {
    height: 40px;
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
  }
  .view {
    width: calc(100% - 80px);
    margin: 40px auto 0;
    height: auto;
    .search {
      margin-bottom: 10px;
    }
    .add {
      width: 100%;
      height: 40px;
      margin: 20px 0;
      display: flex;
      flex-direction: row-reverse;
      .item {
        width: 100px;
        height: 40px;
        background: #35ba83;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
        span {
          margin-left: 5px;
        }
      }
      .item2 {
        width: 140px;
        height: 40px;
        background: #2395F3;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
        span {
          margin-left: 5px;
        }
      }
      .item3 {
        width: 140px;
        height: 38px;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #35BA83;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #35BA83;
        line-height: 38px;
        text-align: center;
        cursor: pointer;
        span {
          margin-left: 5px;
        }
      }
      .btn {
        &+.btn {
            margin-left: 10px;
        }
      }
    }
    .icon {
        font-size: 20px;
        & +.icon {
            margin-left: 20px;
        }
    }
    .icon2 {
        font-size: 20px;
        color: #ffffff;
    }
    .icon3 {
        font-size: 20px;
        color: #35BA83;
    }
    /deep/ .fTable .el-table {
      border-radius: 4px 4px 4px 4px;
      overflow: hidden;
    }
    /deep/ .fp-cell {
      border: 0;
    }
  }
  .tableHeader {
    width: 1078px;
    height: 40px;
    background: #F7F7F7;
    border-radius: 4px 4px 0px 0px;
    opacity: 1;
    border: 1px solid #EEEEEE;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 40px;
    text-align: center;
  }
}
/deep/ .el-dialog__header {
  padding: 8px 20px;
}
.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #EEEEEE;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>
