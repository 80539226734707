<template>
  <el-dialog
    v-drag
    title="新增"
    :visible.sync="dialogVisible"
    width="800px"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="content">
      <el-form ref="form" class="form" :model="form" :rules="rules">
        <div class="form-item">
          <label class="lable"><span class="red">* </span>开始时间</label>
          <el-form-item prop="startTime">
            <el-date-picker
              v-model="form.startTime"
              style="width: 100%"
              value-format="yyyy-MM-dd"
              align="center"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>结束时间</label>
          <el-form-item prop="endTime">
            <el-date-picker
              v-model="form.endTime"
              style="width: 100%"
              value-format="yyyy-MM-dd"
              align="center"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>限制类型</label>
          <el-form-item prop="limitType">
            <el-select
              v-model="form.limitType"
              style="width: 100%"
              placeholder="请选择限制类型"
            >
              <el-option
                v-for="item in limitTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>限制周期(天)</label>
          <el-form-item prop="limitDaysPeriod">
            <el-input-number
              v-model="form.limitDaysPeriod"
              style="width: 100%"
              controls-position="right"
              :min="0"
            ></el-input-number>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>检查基数</label>
          <el-form-item prop="limitNumbers">
            <el-input-number
              v-model="form.limitNumbers"
              style="width: 100%"
              controls-position="right"
              :min="0"
              :precision="2"
            ></el-input-number>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>基数单位</label>
          <el-form-item prop="unitsCode">
            <el-select
              v-model="form.unitsCode"
              style="width: 100%"
              placeholder="请选择优先级"
            >
              <el-option
                v-for="item in unitsCodeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>优先级</label>
          <el-form-item prop="calculateLevel">
            <el-select
              v-model="form.calculateLevel"
              style="width: 100%"
              placeholder="请选择优先级"
            >
              <el-option
                v-for="item in calculateLevelOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>状态</label>
          <el-form-item prop="isActived">
            <el-switch
              v-model="form.isActived"
              active-color="#2395F3"
              inactive-color="#f1f1f1"
            >
            </el-switch>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div class="ok flex_c_c" @click="ok('form')">确 定</div>
    </span>
  </el-dialog>
</template>

<script>
import config from '@/utils/config'
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    editData: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      form: {
        limitType: '',
        limitDaysPeriod: 1,
        limitNumbers: 1,
        unitsCode: '',
        isActived: true,
        calculateLevel: '',
        startTime: '',
        endTime: ''
      },
      rules: {
        limitType: [
          {
            required: true,
            message: '请选择账号限制类型',
            trigger: 'change'
          }
        ],
        limitDaysPeriod: [
          {
            required: true,
            message: '请输入限制周期',
            trigger: 'change'
          }
        ],
        limitNumbers: [
          {
            required: true,
            message: '请输入基数',
            trigger: 'change'
          }
        ],
        unitsCode: [
          {
            required: true,
            message: '请选择基数单位',
            trigger: 'change'
          }
        ],
        calculateLevel: [
          {
            required: true,
            message: '请选择优先级(0-9，0最高)',
            trigger: 'change'
          }
        ],
        startTime: [
          {
            required: true,
            message: '请输入开始时间',
            trigger: 'change'
          }
        ],
        endTime: [
          { required: true, message: '请输入截止时间', trigger: 'change' }
        ]
      },
      limitTypeOptions: config.limitTypeOptions,
      calculateLevelOptions: config.calculateLevelOptions,
      unitsCodeOptions: config.unitsCodeOptions
    }
  },
  methods: {
    cancel() {
      this.$refs['form']?.resetFields()
      this.$emit('cancel')
    },
    ok(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit('ok', this.form)
          this.$refs['form']?.resetFields()
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: calc(100% - 120px);
  height: auto;
  margin: 0 auto;
  text-align: left;
  .form {
    display: flex;
    width: 100%;
    height: auto;
    flex-wrap: wrap;
  }
  .form-item {
    width: calc(50% - 20px);
    position: relative;
    .lable {
      width: 100%;
      height: 19px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      margin-bottom: 5px;
    }
    .red {
      color: rgba(255, 116, 68, 1);
    }
    &:nth-child(2n) {
      margin-left: 40px;
    }
  }
}
/deep/ .el-dialog__body {
  padding: 20px 20px;
}
.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #EEEEEE;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>
