var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: "新增下属客户",
        visible: _vm.dialogVisible,
        width: "520px",
        "show-close": true,
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.cancel,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                "label-position": "top",
                model: _vm.form,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "选择客户", prop: "companyId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "400px" },
                      attrs: { placeholder: "请选择客户" },
                      model: {
                        value: _vm.form.companyId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "companyId", $$v)
                        },
                        expression: "form.companyId",
                      },
                    },
                    _vm._l(_vm.companyDrapdown, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "派送折扣", prop: "deliveryRate" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: { placeholder: "请输入派送折扣" },
                    model: {
                      value: _vm.form.deliveryRate,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "deliveryRate", $$v)
                      },
                      expression: "form.deliveryRate",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { staticClass: "footers", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "div",
            { staticClass: "cancel flex_c_c", on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "div",
            {
              staticClass: "ok flex_c_c",
              on: {
                click: function ($event) {
                  return _vm.ok("ruleForm")
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }