var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: "查看价格",
        visible: _vm.dialogVisible,
        width: "1160px",
        "show-close": true,
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.cancel,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          { staticClass: "tab" },
          [
            _c("BaseTab", {
              staticStyle: { "z-index": "1" },
              attrs: {
                active: _vm.isActive,
                tabItems: _vm.tabItems,
                propWidth: 490,
              },
              on: { tabChange: _vm.tabChange },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "view" },
          [
            _c(
              "div",
              { staticClass: "search" },
              [
                _c(
                  "el-input",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isActive === 0,
                        expression: "isActive === 0",
                      },
                    ],
                    staticClass: "input-with-select",
                    staticStyle: { width: "240px" },
                    attrs: { placeholder: "请输入分区" },
                    model: {
                      value: _vm.text,
                      callback: function ($$v) {
                        _vm.text = $$v
                      },
                      expression: "text",
                    },
                  },
                  [
                    _c("el-button", {
                      attrs: { slot: "append", icon: "el-icon-search" },
                      on: { click: _vm.search },
                      slot: "append",
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("base-table", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isActive === 0,
                  expression: "isActive === 0",
                },
              ],
              attrs: {
                showTooltip: false,
                height: 360,
                border: true,
                tableData: _vm.tableData,
                pageIndex: _vm.pagerConfig.pageIndex,
                pageSize: _vm.pagerConfig.pageSize,
                loading: _vm.loading,
              },
              on: { handlePageChange: _vm.handlePageChange },
            }),
            _c("base-table", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isActive === 1,
                  expression: "isActive === 1",
                },
              ],
              attrs: {
                showTooltip: false,
                isPager: false,
                height: 360,
                border: true,
                tableData: _vm.tableData1,
              },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }