var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: "价格详情",
        visible: _vm.dialogVisible,
        width: "1160px",
        "show-close": true,
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.cancel,
        top: "5%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          { staticClass: "tab" },
          [
            _c("BaseTab", {
              staticStyle: { "z-index": "1" },
              attrs: {
                tabItems: _vm.tabItems,
                propWidth: 490,
                active: _vm.isActive,
              },
              on: { tabChange: _vm.tabChange },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "view" },
          [
            _c("div", { staticClass: "add" }, [
              _c(
                "div",
                { staticClass: "search" },
                [
                  _c("el-input", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isActive === 1,
                        expression: "isActive === 1",
                      },
                    ],
                    staticClass: "input-with-select",
                    attrs: { placeholder: "请输入邮编" },
                    model: {
                      value: _vm.text,
                      callback: function ($$v) {
                        _vm.text = $$v
                      },
                      expression: "text",
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticStyle: { display: "flex" } }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isActive === 0,
                        expression: "isActive === 0",
                      },
                    ],
                    staticClass: "item btn flex_c_c",
                    on: { click: _vm.add },
                  },
                  [
                    _c("i", { staticClass: "el-icon-circle-plus-outline" }),
                    _c("span", [_vm._v("新增")]),
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isActive === 1,
                        expression: "isActive === 1",
                      },
                    ],
                    staticClass: "item3 btn flex_c_c",
                    on: { click: _vm.download },
                  },
                  [
                    _c("svg-icon", {
                      attrs: { iconClass: "download", className: "icon3" },
                    }),
                    _c("span", [_vm._v("下载分区模版")]),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isActive === 1,
                        expression: "isActive === 1",
                      },
                    ],
                    staticClass: "item btn flex_c_c",
                    on: { click: _vm.uploadZoom },
                  },
                  [
                    _c("svg-icon", {
                      attrs: { iconClass: "upload", className: "icon2" },
                    }),
                    _c("span", [_vm._v("导入分区")]),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isActive === 1,
                        expression: "isActive === 1",
                      },
                    ],
                    staticClass: "item2 btn flex_c_c",
                    on: { click: _vm.copyExpressAccountId },
                  },
                  [
                    _c("i", { staticClass: "el-icon-circle-plus-outline" }),
                    _c("span", [_vm._v("复制账号分区")]),
                  ]
                ),
              ]),
            ]),
            _c("base-table", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isActive === 0,
                  expression: "isActive === 0",
                },
              ],
              attrs: {
                showTooltip: false,
                height: 440,
                border: true,
                tableData: _vm.tableData,
                pageIndex: _vm.pagerConfig.pageIndex,
                pageSize: _vm.pagerConfig.pageSize,
                loading: _vm.loading,
              },
              on: { handlePageChange: _vm.handlePageChange },
              scopedSlots: _vm._u([
                {
                  key: "opt",
                  fn: function (row) {
                    return [
                      _c("svg-icon", {
                        attrs: {
                          iconClass: "price-detail",
                          className: "icon",
                          title: "查看价格",
                        },
                        on: {
                          iconClick: function ($event) {
                            return _vm.viewRow(row.scope)
                          },
                        },
                      }),
                      _c("i", {
                        staticClass: "el-icon-edit-outline icon",
                        staticStyle: { color: "#2395f3" },
                        attrs: { title: "修改" },
                        on: {
                          click: function ($event) {
                            return _vm.updateRow(row.scope)
                          },
                        },
                      }),
                      _c("i", {
                        staticClass: "el-icon-delete icon",
                        staticStyle: { color: "#2395f3" },
                        attrs: { title: "删除" },
                        on: {
                          click: function ($event) {
                            return _vm.deleteRow(row.scope)
                          },
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isActive === 1,
                    expression: "isActive === 1",
                  },
                ],
                staticClass: "tableHeader",
              },
              [_vm._v("分区列表")]
            ),
            _c("base-table", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isActive === 1,
                  expression: "isActive === 1",
                },
              ],
              attrs: {
                showTooltip: false,
                height: 400,
                border: true,
                isPager: false,
                tableData: _vm.tableData1,
              },
              scopedSlots: _vm._u([
                {
                  key: "opt",
                  fn: function (row) {
                    return [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "left",
                            width: "240",
                            trigger: "click",
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticStyle: {
                                "font-size": "14px",
                                "font-weight": "400",
                                color: "#333333",
                                "line-height": "20px",
                                "margin-bottom": "20px",
                              },
                            },
                            [_vm._v(" 子列表 ")]
                          ),
                          _c("base-table", {
                            attrs: {
                              showTooltip: false,
                              height: 240,
                              border: true,
                              isPager: false,
                              tableData: {
                                fileds: [
                                  {
                                    filed: "codePrefix",
                                    label: row.scope.zoneExtsList[0]?.type
                                      ? "国家代码"
                                      : "邮编",
                                    width: "",
                                  },
                                ],
                                columns: row.scope.zoneExtsList,
                              },
                            },
                          }),
                          _c(
                            "span",
                            {
                              staticClass: "text",
                              attrs: { slot: "reference" },
                              slot: "reference",
                            },
                            [_vm._v("子列表")]
                          ),
                        ],
                        1
                      ),
                      _c("i", {
                        staticClass: "el-icon-delete icon",
                        staticStyle: { color: "#2395f3" },
                        on: {
                          click: function ($event) {
                            return _vm.deleteRow(row.scope)
                          },
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _c(
        "span",
        { staticClass: "footers", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "div",
            { staticClass: "cancel flex_c_c", on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "div",
            {
              staticClass: "ok flex_c_c",
              on: {
                click: function ($event) {
                  return _vm.ok("form")
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ]
      ),
      _c("view-price-dialog", {
        attrs: {
          dialogVisible: _vm.dialogVisible1,
          expressAccountPriceTemplateId: _vm.editData.id,
        },
        on: { ok: _vm.ok1, cancel: _vm.cancel1 },
      }),
      _c("add-price-dialog", {
        attrs: {
          dialogVisible: _vm.dialogVisible2,
          editData: _vm.editData,
          title: _vm.title,
          expressAccountId: _vm.expressAccountId,
        },
        on: { ok: _vm.ok2, cancel: _vm.cancel2 },
      }),
      _c("upload-zoom-dialog", {
        attrs: {
          dialogVisible: _vm.dialogVisible3,
          expressAccountId: _vm.expressAccountId,
        },
        on: { ok: _vm.ok3, cancel: _vm.cancel3 },
      }),
      _c("copy-account-zoom-dialog", {
        attrs: {
          dialogVisible: _vm.dialogVisible4,
          expressAccountId: _vm.expressAccountId,
        },
        on: { ok: _vm.ok4, cancel: _vm.cancel4 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }