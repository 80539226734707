<template>
  <el-dialog
    v-drag
    title="分票设置"
    :visible.sync="dialogVisible"
    width="800px"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
    top="4%"
  >
    <div class="content">
      <el-form ref="form" class="form" :model="form" :rules="rules">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="form-item">
              <label
                class="lable"
              ><span class="red">* </span>单件重量范围</label
              >
              <el-form-item prop="shipmentWeight">
                <number-range
                  v-model="form.shipmentWeight"
                  :min="1"
                  :max="10000"
                  style="width: 320px;"
                >
                </number-range>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="form-item">
              <label
                class="lable"
              ><span class="red">* </span>单票重量范围</label
              >
              <el-form-item prop="piecesWeight">
                <number-range v-model="form.piecesWeight" :min="1" :max="10000" style="width: 320px;">
                </number-range>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="form-item">
              <label
                class="lable"
              ><span class="red">* </span>单票件数范围</label
              >
              <el-form-item prop="shipmentPcs">
                <number-range v-model="form.shipmentPcs" :min="1" :max="10000" style="width: 320px;">
                </number-range>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>重量系数</label>
          <el-row :gutter="10">
            <el-col :span="5.5">
              <el-form-item prop="eastFactorUS">
                <el-input
                  v-model="form.eastFactorUS"
                  onKeypress="return(/^[0-9.]*$/.test(String.fromCharCode(event.keyCode)))"
                  placeholder="请输入内容"
                  style="width: 155px;"
                >
                  <template slot="prepend">美东</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5.5">
              <el-form-item prop="middleFactorUS">
                <el-input
                  v-model="form.middleFactorUS"
                  onKeypress="return(/^[0-9.]*$/.test(String.fromCharCode(event.keyCode)))"
                  placeholder="请输入内容"
                  style="width: 155px;">
                  <template slot="prepend">美中</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5.5">
              <el-form-item prop="westFactorUS">
                <el-input
                  v-model="form.westFactorUS"
                  onKeypress="return(/^[0-9.]*$/.test(String.fromCharCode(event.keyCode)))"
                  placeholder="请输入内容"
                  style="width: 155px;">
                  <template slot="prepend">美西</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div class="ok flex_c_c" @click="ok('form')">确 定</div>
    </span>
  </el-dialog>
</template>

<script>
const NumberRange = () => import('@/components/base/NumberRange.vue')

export default {
  components: { NumberRange },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    editData: [Object, null]
  },
  data() {
    return {
      form: {
        shipmentWeight: [],
        piecesWeight: [],
        shipmentPcs: [],
        westFactorUS: 0,
        eastFactorUS: 0,
        middleFactorUS: 0
      },
      rules: {
        shipmentWeight: [
          { required: true, message: '单票重量范围', trigger: 'blur' }
        ],
        piecesWeight: [
          { required: true, message: '单件重量范围', trigger: 'blur' }
        ],
        shipmentPcs: [
          { required: true, message: '单票件数范围', trigger: 'change' }
        ],
        westFactorUS: [
          { required: true, message: '美西系数', trigger: 'change' }
        ],
        eastFactorUS: [
          { required: true, message: '美东系数', trigger: 'change' }
        ],
        middleFactorUS: [
          { required: true, message: '美中系数', trigger: 'change' }
        ]
      }
    }
  },
  computed: {},
  watch: {
    dialogVisible: function(value) {
      if (value) {
        if (this.editData) {
            this.form = {
                shipmentWeight: [this.editData.shipmentWeightMin, this.editData.shipmentWeightMax],
                piecesWeight: [this.editData.piecesWeightMin, this.editData.piecesWeightMax],
                shipmentPcs: [this.editData.shipmentPcsMin, this.editData.shipmentPcsMax],
                westFactorUS: this.editData.westFactorUS,
                eastFactorUS: this.editData.eastFactorUS,
                middleFactorUS: this.editData.middleFactorUS
            }
        } else {
          this.form = {
            shipmentWeight: [],
            piecesWeight: [],
            shipmentPcs: [],
            westFactorUS: 0,
            eastFactorUS: 0,
            middleFactorUS: 0
          }
        }
      }
    }
  },
  created() {},
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    ok(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit('ok', {
            shipmentWeightMin: this.form.shipmentWeight[0],
            shipmentWeightMax: this.form.shipmentWeight[1],
            piecesWeightMin: this.form.piecesWeight[0],
            piecesWeightMax: this.form.piecesWeight[1],
            shipmentPcsMin: this.form.shipmentPcs[0],
            shipmentPcsMax: this.form.shipmentPcs[1],
            westFactorUS: this.form.westFactorUS,
            eastFactorUS: this.form.eastFactorUS,
            middleFactorUS: this.form.middleFactorUS
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: calc(100% - 80px);
  height: auto;
  margin: 0 auto;
  padding: 0 40px;
  text-align: left;
  .form {
    width: 100%;
  }
  .form-item {
    position: relative;
    margin-left: 20px;
    &:nth-child(2n + 1) {
      margin-left: 0;
    }
    .lable {
      width: 100%;
      height: 19px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      margin-bottom: 5px;
    }
    .red {
      color: rgba(255, 116, 68, 1);
    }
    &:nth-child(5n + 1) {
      margin-left: 0px;
    }
    /deep/ .input-number-range input {
      border: 0;
    }
    /deep/ .el-input-group__prepend {
      width: 50px;
      height: 40px;
      background: #F7F7F7;
      border-radius: 4px 0px 0px 4px;
      padding: 0;
      text-align: center;
      box-sizing: border-box;
    }
  }
  /deep/ .el-col:nth-child(2n) {
    display: flex;
    justify-content: flex-end;
  }
  .tab {
    width: 100%;
    height: 40px;
    position: relative;
    .line {
      width: 100%;
      height: 1px;
      background: #eeeeee;
      position: absolute;
      top: 19px;
    }
  }
  .list {
    width: 100%;
    max-height: 320px;
    margin-top: 40px;
    .add {
      width: 100%;
      height: 40px;
      margin: 20px 0;
      display: flex;
      flex-direction: row-reverse;
      .item {
        width: 100px;
        height: 40px;
        background: #35ba83;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
        span {
          margin-left: 5px;
        }
      }
      .item2 {
        width: 140px;
        height: 40px;
        background: #2395f3;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
        span {
          margin-left: 5px;
        }
      }
      .item3 {
        width: 140px;
        height: 38px;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #35ba83;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #35ba83;
        line-height: 38px;
        text-align: center;
        cursor: pointer;
        span {
          margin-left: 5px;
        }
      }
      .btn {
        & + .btn {
          margin-left: 10px;
        }
      }
    }
    .icon {
      font-size: 20px;
      & + .icon {
        margin-left: 20px;
      }
    }
    .icon2 {
      font-size: 20px;
      color: #ffffff;
    }
    .icon3 {
      font-size: 20px;
      color: #35ba83;
    }
  }
  .switch-text {
    width: 14px;
    height: 19px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #2395f3;
    line-height: 20px;
    margin-left: 13px;
  }
  .delete {
    font-size: 20px;
    color: #2395f3;
    margin-left: 20px;
    cursor: pointer;
    vertical-align: bottom;
  }
}

.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  .cancel {
    width: 120px;
    height: 40px;
    background: #eeeeee;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
.numberRange {
  width: 175px;
}
.numberRange:deep(.el-input__inner) {
  border: 0;
  height: 38px;
  line-height: 38px;
}
/deep/.el-form-item__content {
  line-height: 0;
}
</style>
