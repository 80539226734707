<template>
  <el-dialog
    v-drag
    title="复制其他账号的下属客户"
    :visible.sync="dialogVisible"
    width="1160px"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="content">
      <div class="opt flex_c_c">
        <el-select
          v-model="expressAccountId"
          filterable
          style="width: 400px"
          placeholder="输入要复制的账号"
          @change="onChange"
        >
          <el-option
            v-for="item in accountOtions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="data">
        <BaseTable
          :height="439"
          :tableData="tableData"
          :loading="loading"
          :border="true"
          :isPager="false"
          @handleSelectionChange="handleSelectionChange"
        >
          <template slot="deliveryRate" slot-scope="row">
            <el-input v-model="row.scope.deliveryRate" placeholder="请输入"></el-input>
          </template>
          <template #custom-total>
            <div class="select">
              已选 <span>{{ selectionItem.length }}</span> 项
            </div>
          </template>
        </BaseTable>
      </div>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div class="ok flex_c_c" @click="ok">确 定</div>
    </span>
  </el-dialog>
</template>

<script>
const BaseTable = () => import('@/components/table/BaseTable')
import {
  expressAccountDrapdown,
  getExpressAccountCompanyList,
  copyExpressAccountCompany
} from '@/api/system'
export default {
  components: { BaseTable },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    editData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      loading: false,
      tableData: {
        fileds: [
          {
            filed: 'identityCode',
            label: '客户编码',
            width: 180,
            resizable: true
          },
          {
            filed: 'companyName',
            label: '客户名称',
            width: 380,
            resizable: true
          },
          {
            filed: 'salesName',
            label: '销售员',
            width: 100,
            resizable: true
          },
          {
            filed: 'currency',
            label: '结算币制',
            width: 100,
            resizable: true
          },
          {
            filed: 'areaValue',
            label: '所属区域',
            width: 160,
            resizable: true
          },
          {
            filed: 'deliveryRate',
            label: '派送折扣',
            width: 100,
            isCustom: true,
            resizable: true
          }
        ],
        columns: [],
        mutiCheck: true
      },
      expressAccountId: '',
      selectionItem: [],
      accountOtions: []
    }
  },
  watch: {
    dialogVisible(val) {
      this.expressAccountId = ''
      this.tableData.columns = []
      if (val) {
        this.getAccountDrapdown()
      }
    }
  },
  methods: {
    async getAccountDrapdown() {
      try {
        const res = await expressAccountDrapdown()
        if (res.success) {
          this.accountOtions = res.data
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    async getList(e) {
      this.loading = true
      try {
        const res = await getExpressAccountCompanyList({ expressAccountId: e })
        if (res.success) {
          this.tableData.columns = res.data
          this.loading = false
        } else {
          this.$message.error(res.errorMessage)
          this.loading = false
        }
      } catch (error) {
        console.error(error)
      }
    },
    onChange(e) {
      this.getList(e)
    },
    cancel() {
      this.$emit('cancel')
    },
    async ok() {
      if (!this.selectionItem.length) {
        this.$message.error('请选择要复制的列')
        return
      }
      try {
        const params = {
          expressAccountId: this.editData.id,
          items: this.selectionItem
        }
        const res = await copyExpressAccountCompany(params)
        if (res.success) {
          this.$message.success(res.errorMessage)
          this.$emit('ok')
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    handleSelectionChange(items) {
      this.selectionItem = items
    }
  }
}
</script>

<style lang="less" scoped>
:deep(.el-dialog__header) {
  border: 0;
}
:deep(.el-dialog__body) {
  padding-top: 0;
}
.content {
  width: calc(100% - 40px);
  height: auto;
  margin: 0 auto;
  .opt {
    margin-bottom: 20px;
  }
  .data {
    width: 100%;
    height: auto;
    /deep/ .fTable .el-table {
      border-radius: 4px 4px 4px 4px;
      overflow: hidden;
      .fp-cell {
        border: 0;
      }
    }
    /deep/ .el-table--border .el-table__cell:first-child .cell {
      padding: 0;
    }
    .select {
      margin-top: 15px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      & span {
        color: #2395f3;
      }
    }
    /deep/ input {
      border: 0;
      text-align: center;
    }
    /deep/ tr td,
    /deep/ tr td .cell {
      padding: 0;
    }
  }
}
.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #eeeeee;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 20px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>
