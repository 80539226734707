<template>
  <el-dialog
    v-drag
    :title="title"
    :visible.sync="dialogVisible"
    width="1160px"
    :show-close="true"
    top="0.5%"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="content">
      <el-form ref="form" class="form" :model="form" :rules="rules">
        <div class="form-item">
          <label class="lable"><span class="red">* </span>账号</label>
          <el-form-item prop="accountNumber">
            <el-input
              v-model="form.accountNumber"
              placeholder="请输入账号"
            ></el-input>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>快递服务商</label>
          <el-form-item prop="expressSupplierId">
            <el-select
              v-model="form.expressSupplierId"
              style="width: 100%"
              placeholder="请选择快递服务商"
              @change="supplyChange"
            >
              <el-option
                v-for="item in esIdOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>服务类型</label>
          <el-form-item prop="expressSupplierServiceId">
            <el-select
              v-model="form.expressSupplierServiceId"
              style="width: 100%"
              placeholder="请选择服务类型"
              @change="serviceChange"
            >
              <el-option
                v-for="item in esServiceIdOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>对接来源</label>
          <el-form-item prop="expressSupplierThirdPartyId">
            <el-cascader
              v-model="form.expressSupplierThirdPartyId"
              :options="ESTPoptions"
              @change="serviceSupplierChange"
            ></el-cascader>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>状态</label>
          <el-form-item prop="isActived">
            <el-switch
              v-model="form.isActived"
              active-color="#2395F3"
              inactive-color="#f1f1f1"
            >
            </el-switch>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>材积除数</label>
          <el-form-item prop="vFlag">
            <el-input-number
              v-model="form.vFlag"
              style="width: 100%"
              controls-position="right"
              :min="0"
              :precision="2"
              :step="1"
            ></el-input-number>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>材积单位</label>
          <el-form-item prop="dimensionUnit">
            <el-select
              v-model="form.dimensionUnit"
              style="width: 100%"
              placeholder="请选择材积单位"
            >
              <el-option
                v-for="item in dimensionOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>计算方式</label>
          <el-form-item prop="calculateType">
            <el-select
              v-model="form.calculateType"
              style="width: 100%"
              placeholder="请选择计算方式"
            >
              <el-option
                v-for="item in calculateOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>单票最高件数</label>
          <el-form-item prop="shipmentMaxPcs">
            <el-input-number
              v-model="form.shipmentMaxPcs"
              style="width: 100%"
              controls-position="right"
              :min="1"
              :step="1"
            ></el-input-number>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>账号使用费</label>
          <el-form-item prop="usageRate">
            <el-input-number
              v-model="form.usageRate"
              style="width: 100%"
              controls-position="right"
              :precision="2"
              :min="1"
              :step="1"
            ></el-input-number>
          </el-form-item>
        </div>
        <div class="form-item" style="width: calc(40% - 12px)">
          <div style="display: flex; justify-content: space-between">
            <div>
              <label class="lable"><span class="red">* </span>限重</label>
              <el-form-item prop="isWeightLimit">
                <el-select
                  v-model="form.isWeightLimit"
                  style="width: 60px"
                  placeholder="请选择服务类型"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div v-if="form.isWeightLimit" class="numberRange">
              <label
                class="lable"
              ><span class="red">* </span>单票重量范围(1-10000)</label
              >
              <el-form-item prop="shipmentWeight">
                <number-range
                  v-model="form.shipmentWeight"
                  :min="1"
                  :max="10000"
                >
                </number-range>
              </el-form-item>
            </div>
            <div v-if="form.isWeightLimit" class="numberRange">
              <label
                class="lable"
              ><span class="red">* </span>单件重量范围(1-10000)</label
              >
              <el-form-item prop="piecesWeight">
                <number-range v-model="form.piecesWeight" :min="1" :max="10000">
                </number-range>
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>重量单位</label>
          <el-form-item prop="weightUnit">
            <el-select
              v-model="form.weightUnit"
              style="width: 100%"
              placeholder="请选择计算方式"
            >
              <el-option
                v-for="item in weightUnitOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="form-item" style="width: calc(40% - 12px)">
          <div style="display: flex; justify-content: space-between">
            <div>
              <label class="lable"><span class="red">* </span>低消</label>
              <el-form-item prop="isPriceLimit">
                <el-select
                  v-model="form.isPriceLimit"
                  style="width: 60px"
                  placeholder="请选择低消"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div v-if="form.isPriceLimit">
              <label class="lable"><span class="red">* </span>单票低消</label>
              <el-form-item prop="shipmentLowestAmount">
                <el-input-number
                  v-model="form.shipmentLowestAmount"
                  style="width: 175px"
                  controls-position="right"
                  :min="1"
                  :step="1"
                  :precision="2"
                ></el-input-number>
              </el-form-item>
            </div>
            <div v-if="form.isPriceLimit">
              <label class="lable"><span class="red">* </span>单件低消</label>
              <el-form-item prop="piecesLowestAmount">
                <el-input-number
                  v-model="form.piecesLowestAmount"
                  style="width: 175px"
                  controls-position="right"
                  :min="1"
                  :step="1"
                  :precision="2"
                ></el-input-number>
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="form-item" style="margin-left: 0">
          <label class="lable"><span class="red">* </span>账号供应商</label>
          <el-form-item prop="supplierId">
            <el-select v-model="form.supplierId" placeholder="请选择账号供应商">
              <el-option
                v-for="item in supplierOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="form-item flex_c" style="margin-left: 40px">
          <el-checkbox v-model="form.interceptRemote" label="拦截偏远地址"></el-checkbox>
          <el-checkbox v-model="form.interceptResidence " label="拦截住宅地址"></el-checkbox>
        </div>
        <div class="form-item" style="margin-left: 85px;width: 80px;">
          <label class="lable"><span class="red">* </span>支持分票</label>
          <span>
            <el-switch
              v-model="form.isSplit"
              style="display: block;margin-top: 10px;"
              active-color="#2395F3"
              :active-text="`${form.isSplit ? '启用' : '关闭'}`"
              inactive-color="#CDCDCD">
            </el-switch>
          </span>
        </div>
        <div v-if="form.isSplit" class="form-item link-detail" @click="dialogVisible1 = true">
          [查看详情]
        </div>
      </el-form>
      <div class="tab">
        <div class="line"></div>
        <BaseTab
          style="z-index: 1"
          :tabItems="tabItems"
          :propWidth="490"
          :active="isActive"
          @tabChange="tabChange"
        ></BaseTab>
      </div>
      <div class="list">
        <params-info
          v-show="isActive === 0"
          ref="paramsInfo"
          :paramsLists="paramsLists"
        ></params-info>
        <account-restrictions
          v-show="isActive === 1"
          ref="accountRestrictions"
          :editData="limitList"
        ></account-restrictions>
      </div>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div class="ok flex_c_c" @click="ok('form')">确 定</div>
    </span>
    <SplitTicketSetupDialog :editData="form.splitSetting" :dialogVisible="dialogVisible1" @ok="ok1" @cancel="dialogVisible1 = false"></SplitTicketSetupDialog>
  </el-dialog>
</template>

<script>
const BaseTab = () => import('@/components/tab/BaseTab')
const NumberRange = () => import('@/components/base/NumberRange.vue')
import SplitTicketSetupDialog from './SplitTicketSetupDialog.vue'
import {
  getExpressSupplierDefaultParamsList,
  getExpressSupplierThirdPartyList,
  GetSupplierPageList,
  insertExpressAccount,
  getExpressAccountSetting,
  updateExpressAccount
} from '@/api/system'
import ParamsInfo from './ParamsInfo.vue'
import AccountRestrictions from './AccountRestrictions.vue'
import config from '@/utils/config'
export default {
  components: { BaseTab, ParamsInfo, AccountRestrictions, NumberRange, SplitTicketSetupDialog },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    supplyService: {
      type: Array,
      default: function() {
        return []
      }
    },
    editData: {
      type: Object,
      default: function() {
        return {}
      }
    },
    title: {
      type: String,
      default: '新增'
    }
  },
  data() {
    return {
      tabItems: [
        {
          id: 0,
          name: '* 参数信息'
        },
        {
          id: 1,
          name: '账号限制'
        }
      ],
      form: {
        expressSupplierId: '',
        expressSupplierServiceId: '',
        expressSupplierThirdPartyId: '',
        supplierId: '',
        vFlag: 0,
        dimensionUnit: 0,
        weightUnit: 0,
        apiSource: 0,
        accountNumber: '',
        shipmentMaxPcs: 1,
        isWeightLimit: true,
        shipmentMaxWeight: 0,
        shipmentMinWeight: 0,
        shipmentWeight: [1, 10000],
        piecesWeight: [1, 10000],
        piecesMaxWeight: 0,
        piecesMinWeight: 0,
        isPriceLimit: true,
        piecesLowestAmount: 0,
        shipmentLowestAmount: 0,
        usageRate: 1,
        calculateType: 0,
        isActived: true,
        paramsValueList: [],
        limitList: [],
        interceptRemote: false,
        interceptResidence: false,
        isSplit: false
      },
      rules: {
        accountNumber: [
          { required: true, message: '请输入账号', trigger: 'blur' }
        ],
        expressSupplierId: [
          { required: true, message: '请选择快递服务商', trigger: 'change' }
        ],
        expressSupplierServiceId: [
          { required: true, message: '请选择服务类型', trigger: 'change' }
        ],
        expressSupplierThirdPartyId: [
          { required: true, message: '请选择对接来源', trigger: 'change' }
        ],
        vFlag: [{ required: true, message: '请输入材积除数', trigger: 'blur' }],
        dimensionUnit: [
          { required: true, message: '请选择材积单位', trigger: 'blur' }
        ],
        calculateType: [
          { required: true, message: '请选择计算方式', trigger: 'blur' }
        ],
        shipmentMaxPcs: [
          { required: true, message: '请输入单票最高件数', trigger: 'blur' }
        ],
        usageRate: [
          { required: true, message: '请输入账号使用费', trigger: 'blur' }
        ],
        shipmentWeight: [
          {
            required: true,
            message: '请输入单票重量范围(1-10000)',
            trigger: 'blur'
          }
        ],
        piecesWeight: [
          {
            required: true,
            message: '请输入单件重量范围(1-10000)',
            trigger: 'blur'
          }
        ],
        weightUnit: [
          { required: true, message: '请选择重量单位', trigger: 'blur' }
        ],
        shipmentLowestAmount: [
          { required: true, message: '请选择单票低消', trigger: 'blur' }
        ],
        piecesLowestAmount: [
          { required: true, message: '请选择单件低消', trigger: 'blur' }
        ],
        supplierId: [
          { required: true, message: '请选择账号供应商', trigger: 'cahnge' }
        ]
      },
      isActive: 0,
      paramsLists: [],
      ESTPoptions: [],
      dimensionOptions: config.dimensionOptions,
      calculateOptions: config.calculateOptions,
      weightUnitOptions: config.weightUnitOptions,
      options: [
        {
          value: true,
          label: '是'
        },
        {
          value: false,
          label: '否'
        }
      ],
      supplierOptions: [],
      limitList: [],
      value: false,
      dialogVisible1: false
    }
  },
  computed: {
    esIdOptions: function() {
      const arr = this.supplyService.map((item) => {
        return {
          label: item.name,
          value: item.id
        }
      })
      return arr || []
    },
    esServiceIdOptions: function() {
      const service = this.supplyService.find((item) => {
        if (this.form.expressSupplierId) {
          return item.id === this.form.expressSupplierId
        } else {
           return item.id === this.form.expressSupplierId
        }
      })
      const arr =
        service &&
        service.serviceTypeList.map((item) => {
          return {
            label: item.name,
            value: item.id
          }
        })
      return arr || []
    }
  },
  watch: {
    dialogVisible: function(value) {
      if (value) {
        this.getSupplyList()
        this.limitList = []
        this.paramsLists = []
        if (this.editData.id) {
          let idArr = []
          if (this.editData.expressSupplierThirdPartyId) {
            idArr = [1, this.editData.expressSupplierThirdPartyId]
          } else {
            idArr = [0]
          }
          this.form = {
            ...this.editData,
            expressSupplierThirdPartyId: idArr,
            shipmentWeight: [
              this.editData.shipmentMinWeight,
              this.editData.shipmentMaxWeight
            ],
            piecesWeight: [
              this.editData.piecesMinWeight,
              this.editData.piecesMaxWeight
            ]
          }
          this.updateInit()
        } else {
          this.form = {
            expressSupplierId: '',
            expressSupplierServiceId: '',
            expressSupplierThirdPartyId: '',
            supplierId: '',
            vFlag: 0,
            dimensionUnit: 0,
            weightUnit: 0,
            apiSource: 0,
            accountNumber: '',
            shipmentMaxPcs: 1,
            isWeightLimit: true,
            shipmentMaxWeight: 0,
            shipmentMinWeight: 0,
            shipmentWeight: [1, 10000],
            piecesWeight: [1, 10000],
            piecesMaxWeight: 0,
            piecesMinWeight: 0,
            isPriceLimit: true,
            piecesLowestAmount: 0,
            shipmentLowestAmount: 0,
            usageRate: 1,
            calculateType: 0,
            isActived: true,
            paramsValueList: [],
            limitList: []
          }
        }
      }
    }
  },
  created() {},
  methods: {
    cancel() {
      this.isActive = 0
      this.$refs['form']?.resetFields()
      this.$emit('cancel')
    },
    ok(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log('valid')
          const paramInfo = this.$refs.paramsInfo.validate()
          if (!paramInfo) {
            this.$message.warning('未填写参数信息')
            return
          }
          if (this.form.isSplit && !this.form.splitSetting) {
            this.$message.warning('未填写分票设置')
            return
          }
          const accountRestrictions = this.$refs.accountRestrictions.getData()
          const params = {
            ...this.form,
            shipmentMinWeight: this.form.shipmentWeight[0],
            shipmentMaxWeight: this.form.shipmentWeight[1],
            piecesMinWeight: this.form.piecesWeight[0],
            piecesMaxWeight: this.form.piecesWeight[1],
            expressSupplierThirdPartyId:
              this.form.expressSupplierThirdPartyId[
                this.form.expressSupplierThirdPartyId.length - 1
              ],
            apiSource: this.form.expressSupplierThirdPartyId[0],
            paramsValueList: [...paramInfo],
            limitList: [...accountRestrictions]
          }
          console.log('params', params)
          if (!params.id) {
            insertExpressAccount(params).then((res) => {
              if (res.success) {
                this.$emit('ok')
                this.$refs[formName]?.resetFields()
                this.$message.success('新增成功')
              } else {
                this.$message.error(res.errorMessage)
              }
            })
          } else {
            updateExpressAccount(params).then((res) => {
              if (res.success) {
                this.$emit('ok')
                this.$refs[formName]?.resetFields()
                this.$message.success('修改成功')
              } else {
                this.$message.error(res.errorMessage)
              }
            })
          }
        }
      })
    },
    tabChange(item) {
      this.isActive = item.id
    },
    async getParamsDetail(expressSupplierId) {
      const res = await getExpressSupplierDefaultParamsList({
        expressSupplierId
      })
      if (res.success) {
        this.paramsLists = res.data
      }
    },
    async getSupplyList() {
      const res = await GetSupplierPageList({
        pageIndex: 1,
        pageSize: 1000,
        queryParam: {}
      })
      if (res.success) {
        console.log(res)
        const arr = []
        res.data &&
          res.data.list.map((item) => {
            arr.push({
              value: item.id,
              label: item.wbName
            })
          })
        this.supplierOptions = arr
      } else {
        this.$message.error(res.errorMessage)
      }
    },
    supplyChange() {
      this.form.expressSupplierServiceId = ''
    },
    async updateInit() {
      try {
        const res = await getExpressSupplierDefaultParamsList({
          expressSupplierId: this.editData.expressSupplierServiceId,
           expressSupplierThirdPartyId: this.editData.expressSupplierThirdPartyId || undefined
        })
        const res1 = await getExpressAccountSetting({
          expressAccountId: this.editData.id
        })
        const res2 = await getExpressSupplierThirdPartyList({
          expressSupplierId: this.editData.expressSupplierServiceId
        })
        if (res2.success) {
          const arr = [
            {
              value: 0,
              label: '官方'
            },
            {
              value: 1,
              label: '第三方',
              children: []
            }
          ]
          res2.data &&
            res2.data.map((item) => {
              arr[1].children.push({
                value: item.id,
                label: item.name
              })
            })
          this.ESTPoptions = arr
        }
        // this.serviceChange(this.editData.expressSupplierServiceId)
        if (res1.success && res.success) {
          const lists = res.data.map((item) => {
            const list = res1.data.paramsValueList.find(
              (a) => a.expressSupplierDefaultParamsId === item.id
            )
            if (list && list.value) {
              if (typeof list.value === 'string') {
                item.value = list.value
              } else {
                item.value = JSON.parse(list.value)
              }
            } else {
              item.value = ''
            }
            return item
          })
          this.paramsLists = lists
          const limitLists = res1.data.limitList.map((item) => {
            item.startTime = item.startTime.split(' ')[0]
            item.endTime = item.endTime.split(' ')[0]
            return item
          })
          this.limitList = limitLists
        }
      } catch (error) {
        console.log(error)
      }
    },
    async serviceChange(value) {
      try {
        const res = await getExpressSupplierThirdPartyList({
          expressSupplierId: value
        })
        if (res.success) {
          const arr = [
            {
              value: 0,
              label: '官方'
            },
            {
              value: 1,
              label: '第三方',
              children: []
            }
          ]
          res.data &&
            res.data.map((item) => {
              arr[1].children.push({
                value: item.id,
                label: item.name
              })
            })
          this.ESTPoptions = arr
          this.form.expressSupplierThirdPartyId = [0]
        }
        this.getParamsDetail(value)
      } catch (error) {
        console.log(error)
      }
    },
    async serviceSupplierChange(value) {
      if (value[0] === 0) {
        const res = await getExpressSupplierDefaultParamsList({
          expressSupplierId: this.form.expressSupplierServiceId
        })
        if (res.success) {
          this.paramsLists = res.data
        } else {
          this.$message.error(res.errorMessage)
        }
      } else {
        const res = await getExpressSupplierDefaultParamsList({
          expressSupplierId: this.form.expressSupplierServiceId,
          expressSupplierThirdPartyId: value[1]
        })
        if (res.success) {
          this.paramsLists = res.data
        } else {
          this.$message.error(res.errorMessage)
        }
      }
    },
    ok1(obj) {
      this.dialogVisible1 = false
      this.form.splitSetting = obj
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: calc(100% - 40px);
  height: auto;
  margin: 0 auto;
  text-align: left;
  .form {
    display: flex;
    width: 100%;
    height: auto;
    flex-wrap: wrap;
  }
  .form-item {
    width: calc(20% - 16px);
    position: relative;
    margin-left: 20px;
    .lable {
      width: 100%;
      height: 19px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      margin-bottom: 5px;
    }
    .red {
      color: rgba(255, 116, 68, 1);
    }
    &:nth-child(5n + 1) {
      margin-left: 0px;
    }
  }
  .link-detail {
    width: 70px;
    height: 82px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #35BA83;
    line-height: 82px;
    margin-left: 0px;
    cursor: pointer;

  }
  .flex_c {
    display: flex;
    align-items: center;
  }
  .tab {
    width: 100%;
    height: 40px;
    position: relative;
    .line {
      width: 100%;
      height: 1px;
      background: #eeeeee;
      position: absolute;
      top: 19px;
    }
  }
  .list {
    width: 100%;
    max-height: 320px;
    margin-top: 40px;
    /deep/ .fTable .el-table {
      border-radius: 4px 4px 4px 4px;
      overflow: hidden;
    }
    /deep/ .fp-cell {
      border: 0;
    }
  }
}
/deep/ .el-dialog__body {
  padding: 20px 20px;
}
.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #EEEEEE;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
.numberRange {
  width: 175px;
}
.numberRange:deep(.el-input__inner) {
  border: 0;
  height: 38px;
  line-height: 38px;
}
/deep/.el-form-item__content {
  line-height: 0;
}
</style>
