<template>
  <el-dialog
    v-drag
    title="价格详情"
    :visible.sync="dialogVisible"
    width="1160px"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
    top="5%"
  >
    <div class="content">
      <div class="tab">
        <BaseTab
          style="z-index: 1"
          :tabItems="tabItems"
          :propWidth="490"
          :active="isActive"
          @tabChange="tabChange"
        ></BaseTab>
      </div>
      <div class="view">
        <div class="add">
          <div class="search">
            <el-input v-show="isActive === 1" v-model="text" placeholder="请输入邮编" class="input-with-select">
            </el-input>
          </div>
          <div style="display: flex">
            <div v-show="isActive === 0" class="item btn flex_c_c" @click="add">
              <i class="el-icon-circle-plus-outline" />
              <span>新增</span>
            </div>
            <div v-show="isActive === 1" class="item3 btn flex_c_c" @click="download">
              <svg-icon iconClass="download" className="icon3" />
              <span>下载分区模版</span>
            </div>
            <div v-show="isActive === 1" class="item btn flex_c_c" @click="uploadZoom">
              <svg-icon iconClass="upload" className="icon2" />
              <span>导入分区</span>
            </div>
            <div
              v-show="isActive === 1"
              class="item2 btn flex_c_c"
              @click="copyExpressAccountId"
            >
              <i class="el-icon-circle-plus-outline" />
              <span>复制账号分区</span>
            </div>
          </div>
        </div>
        <base-table
          v-show="isActive === 0"
          :showTooltip="false"
          :height="440"
          :border="true"
          :tableData="tableData"
          :pageIndex="pagerConfig.pageIndex"
          :pageSize="pagerConfig.pageSize"
          :loading="loading"
          @handlePageChange="handlePageChange"
        >
          <template slot="opt" slot-scope="row">
            <svg-icon
              iconClass="price-detail"
              className="icon"
              title="查看价格"
              @iconClick="viewRow(row.scope)"
            />
            <i
              class="el-icon-edit-outline icon"
              style="color: #2395f3"
              title="修改"
              @click="updateRow(row.scope)"
            />
            <i
              class="el-icon-delete icon"
              style="color: #2395f3"
              title="删除"
              @click="deleteRow(row.scope)"
            />
          </template>
        </base-table>
        <div v-show="isActive === 1" class="tableHeader">分区列表</div>
        <base-table
          v-show="isActive === 1"
          :showTooltip="false"
          :height="400"
          :border="true"
          :isPager="false"
          :tableData="tableData1"
        >
          <template slot="opt" slot-scope="row">
            <el-popover placement="left" width="240" trigger="click">
              <p
                style="
                  font-size: 14px;
                  font-weight: 400;
                  color: #333333;
                  line-height: 20px;
                  margin-bottom: 20px;
                "
              >
                子列表
              </p>
              <base-table
                :showTooltip="false"
                :height="240"
                :border="true"
                :isPager="false"
                :tableData="{
                  fileds: [
                    {
                      filed: 'codePrefix',
                      label: row.scope.zoneExtsList[0]?.type
                        ? '国家代码'
                        : '邮编',
                      width: '',
                    },
                  ],
                  columns: row.scope.zoneExtsList,
                }"
              >
              </base-table>
              <span slot="reference" class="text">子列表</span>
            </el-popover>
            <i
              class="el-icon-delete icon"
              style="color: #2395f3"
              @click="deleteRow(row.scope)"
            />
          </template>
        </base-table>
      </div>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div class="ok flex_c_c" @click="ok('form')">确 定</div>
    </span>
    <view-price-dialog
      :dialogVisible="dialogVisible1"
      :expressAccountPriceTemplateId="editData.id"
      @ok="ok1"
      @cancel="cancel1"
    ></view-price-dialog>
    <add-price-dialog
      :dialogVisible="dialogVisible2"
      :editData="editData"
      :title="title"
      :expressAccountId="expressAccountId"
      @ok="ok2"
      @cancel="cancel2"
    ></add-price-dialog>
    <upload-zoom-dialog
      :dialogVisible="dialogVisible3"
      :expressAccountId="expressAccountId"
      @ok="ok3"
      @cancel="cancel3"
    ></upload-zoom-dialog>
    <copy-account-zoom-dialog
      :dialogVisible="dialogVisible4"
      :expressAccountId="expressAccountId"
      @ok="ok4"
      @cancel="cancel4"
    >
    </copy-account-zoom-dialog>
  </el-dialog>
</template>

<script>
import AddPriceDialog from './AddPriceDialog.vue'
import UploadZoomDialog from './UploadZoomDialog.vue'
import ViewPriceDialog from './ViewPriceDialog.vue'
import CopyAccountZoomDialog from './CopyAccountZoomDialog.vue'
const BaseTab = () => import('@/components/tab/BaseTab.vue')
const BaseTable = () => import('@/components/table/BaseTable.vue')
import {
  getExpressAccountZone,
  deleteExpressAccountZone,
  getExpressAccountPriceTemplatePageList,
  deleteExpressAccountPriceTemplate
} from '@/api/system'
import { mapGetters } from 'vuex'
import { treeToList } from '@/utils/common.js'
import config from '@/utils/config'
export default {
  components: {
    BaseTab,
    BaseTable,
    ViewPriceDialog,
    AddPriceDialog,
    UploadZoomDialog,
    CopyAccountZoomDialog
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    expressAccountId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      tabItems: [
        {
          id: 0,
          name: '价格设置'
        },
        {
          id: 1,
          name: '分区'
        }
      ],
      isActive: 0,
      tableData: {
        fileds: [
          {
            filed: 'code',
            label: '模板代码',
            width: '120',
            resizable: true
          },
          {
            filed: 'name',
            label: '模板名称',
            width: '220',
            resizable: true
          },
          {
            filed: 'originCityId',
            label: '始发地',
            width: '',
            formatter: (row) => {
              let str = ''
              if (row.countryId) {
                const item = this.addressDropOption.find(
                  (a) => a.value === row.countryId
                )
                if (item) {
                  const list = treeToList([item], 'children')
                  const m = list.find((a) => a.value === row.originCityId)
                  str = m.label
                }
              }
              return str
            },
            resizable: true
          },
          {
            filed: 'validFrom',
            label: '开始日期',
            width: '120',
            formatter: (row) => {
              return row.validFrom.split(' ')[0]
            },
            resizable: true
          },
          {
            filed: 'validTo',
            label: '结束日期',
            width: '120',
            formatter: (row) => {
              return row.validTo.split(' ')[0]
            },
            resizable: true
          },
          {
            filed: 'isFuelCharge',
            label: '报价含燃油',
            width: '80',
            formatter: (row) => {
              return row.isFuelCharge ? '是' : '否'
            },
            resizable: true
          },
          {
            filed: 'type',
            label: '报价类型',
            width: '80',
            formatter: (row) => {
              const item = config.accountPriceTplOptions.find(
                (a) => a.value === row.type
              )
              return item.label
            },
            resizable: true
          },
          {
            filed: 'isOtherCharge',
            label: '附加费',
            width: '80',
            formatter: (row) => {
              return row.isOtherCharge ? '是' : '否'
            },
            resizable: true
          },
          {
            filed: 'opt',
            label: '操作',
            width: '',
            isCustom: true,
            resizable: true
          }
        ],
        columns: [],
        total: 0
      },
      pagerConfig: {
        pageIndex: 1,
        pageSize: 20
      },
      tableData1: {
        fileds: [
          {
            filed: 'countryId',
            label: '国家',
            width: '',
            formatter: (row) => {
              let str = ''
              if (row.countryId) {
                const item = this.addressDropOption.find(
                  (a) => a.value === row.countryId
                )
                if (item) {
                  str = item.label
                }
              }
              return str
            },
            resizable: true
          },
          {
            filed: 'zoneCode',
            label: '代码',
            width: '',
            resizable: true
          },
          {
            filed: 'originCityId',
            label: '始发城市',
            width: '',
            formatter: (row) => {
              let str = ''
              if (row.countryId) {
                const item = this.addressDropOption.find(
                  (a) => a.value === row.countryId
                )
                if (item) {
                  const list = treeToList([item], 'children')
                  const m = list.find((a) => a.value === row.originCityId)
                  str = m.label
                }
              }
              return str
            },
            resizable: true
          },
          {
            filed: 'createdName',
            label: '创建人',
            width: '',
            resizable: true
          },
          {
            filed: 'createdOn',
            label: '创建时间',
            width: '',
            resizable: true
          },
          {
            filed: 'opt',
            label: '操作',
            width: '',
            isCustom: true,
            resizable: true
          }
        ],
        columns: [],
        tableRowClassName: ({ row }) => {
          let flag = false
          row.zoneExtsList.forEach(a => {
            if (a.codePrefix === this.text) {
              flag = true
            }
          })
          return flag ? 'high-row' : ''
        }
      },
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false,
      uploadData: {},
      loading: true,
      editData: {},
      title: '新增',
      text: ''
    }
  },
  computed: {
    ...mapGetters(['addressDropOption'])
  },
  watch: {
    dialogVisible: function(val) {
      if (val) {
        this.getTplList(this.pagerConfig)
        this.getZoomList()
        this.text = ''
      }
    }
  },
  mounted() {
    if (!this.addressDropOption || this.addressDropOption.length === 0) {
      this.$store.dispatch('getAddress')
    }
  },
  methods: {
    async getZoomList() {
      try {
        const res = await getExpressAccountZone({
          expressAccountId: this.expressAccountId
        })
        if (res.success) {
          console.log(res)
          this.tableData1.columns = res.data
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.log(error)
      }
    },
    async getTplList(params) {
      try {
        this.loading = true
        const res = await getExpressAccountPriceTemplatePageList({
          ...params,
          queryParam: {
            expressAccountId: this.expressAccountId
          }
        })
        if (res.success) {
          console.log(res)
          this.loading = false
          this.tableData.columns = res.data.list
          this.tableData.total = res.data.total
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.log(error)
      }
    },
    handlePageChange(pager) {
      this.pagerConfig = {
        ...this.pagerConfig,
        ...pager
      }
      const params = {
        ...this.pagerConfig,
        ...pager
      }
      this.getTplList(params)
    },
    deleteRow(row) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        iconClass: 'warning',
        showClose: false,
        closeOnPressEscape: false,
        closeOnClickModal: false,
        center: true
      })
        .then(() => {
          if (this.isActive === 0) {
            deleteExpressAccountPriceTemplate({
              id: row.id
            }).then((res) => {
              if (res.success) {
                this.$message.success('删除成功')
                this.getTplList(this.pagerConfig)
              } else {
                this.$message.error(res.errorMessage)
              }
            })
          } else {
            deleteExpressAccountZone({
              id: row.id
            }).then((res) => {
              if (res.success) {
                this.$message.success('删除成功')
                this.getZoomList()
              } else {
                this.$message.error(res.errorMessage)
              }
            })
          }
        })
        .catch(() => {})
    },
    cancel() {
      this.isActive = 0
      this.$emit('cancel')
    },
    ok() {
      this.$emit('ok')
    },
    tabChange(item) {
      this.isActive = item.id
    },
    viewRow(row) {
      this.editData = row
      this.dialogVisible1 = true
    },
    ok1() {
      this.cancel1()
    },
    cancel1() {
      this.editData = {}
      this.dialogVisible1 = false
    },
    add() {
      this.title = '新增'
      this.dialogVisible2 = true
    },
    updateRow(row) {
      this.title = '修改'
      this.editData = row
      this.dialogVisible2 = true
    },
    ok2() {
      this.pagerConfig = {
        pageIndex: 1,
        pageSize: 20
      }
      this.getTplList({
        pageIndex: 1,
        pageSize: 20
      })
      this.cancel2()
    },
    cancel2() {
      this.dialogVisible2 = false
      this.editData = {}
    },
    uploadZoom() {
      this.dialogVisible3 = true
    },
    ok3() {
      this.getZoomList()
      this.cancel3()
    },
    cancel3() {
      this.dialogVisible3 = false
    },
    copyExpressAccountId() {
      this.dialogVisible4 = true
    },
    ok4() {
      this.getZoomList()
      this.cancel4()
    },
    cancel4() {
      this.dialogVisible4 = false
    },
    priceDetail() {},
    async download() {
      const elink = document.createElement('a')
      elink.style.display = 'none'
      elink.href =
        `/backEnd/api/${config.supplyCode}/file/downLoadTemplate?guid=e77ab84e1a24223470e110524d7ec1f0`
      document.body.appendChild(elink)
      elink.click()
      URL.revokeObjectURL(elink.href)
      document.body.removeChild(elink)
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  height: auto;
  margin: 0 auto;
  text-align: left;
  .tab {
    height: 40px;
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
  }
  .view {
    width: calc(100% - 80px);
    margin: 20px auto 0;
    height: auto;
    .add {
      width: 100%;
      height: 40px;
      margin: 0 0 20px;
      display: flex;
      justify-content: space-between;
      .item {
        width: 100px;
        height: 40px;
        background: #35ba83;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        cursor: pointer;
        span {
          margin-left: 5px;
        }
      }
      .item2 {
        width: 140px;
        height: 40px;
        background: #2395f3;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        cursor: pointer;
        span {
          border-radius: 0px 4px 4px 0px;
        }
      }
      .search-btn {
        background: #2395f3;
        color: #ffffff;
        border-radius: 0;
      }
      .item3 {
        width: 140px;
        height: 38px;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #35ba83;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #35ba83;
        cursor: pointer;
        span {
          margin-left: 5px;
        }
      }
      .btn {
        & + .btn {
          margin-left: 10px;
        }
      }
    }
    .icon {
      font-size: 20px;
      vertical-align: text-top;
      cursor: pointer;
      & + .icon {
        margin-left: 20px;
      }
    }
    .icon2 {
      font-size: 20px;
      color: #ffffff;
    }
    .icon3 {
      font-size: 20px;
      color: #35ba83;
    }
    /deep/ .fTable .el-table {
      border-radius: 4px 4px 4px 4px;
      overflow: hidden;
    }
  }
  .tableHeader {
    width: 100%;
    height: 40px;
    background: #f7f7f7;
    border-radius: 4px 4px 0px 0px;
    border: 1px solid #eeeeee;
    border-bottom: 0;
    box-sizing: border-box;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 40px;
    text-align: center;
  }
  .text {
    width: 42px;
    height: 19px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #2395f3;
    line-height: 20px;
    margin-right: 20px;
    cursor: pointer;
  }
}
/deep/ .el-dialog__body {
  padding-left: 0;
  padding-right: 0;
}
/deep/ .el-popover {
  padding: 20px;
}
// /deep/ .el-dialog__header {
// //   padding: 8px 20px;
// }
.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #EEEEEE;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>
