<template>
  <BaseTable
    :isPager="false"
    :showTooltip="false"
    :height="320"
    :border="true"
    :tableData="tableData"
  >
    <template slot="description" slot-scope="row">
      <el-input v-model="row.scope.description" placeholder="请输入描述内容" />
    </template>
    <template slot="value" slot-scope="row">
      <template v-if="row.scope.type === 0">
        <el-input
          v-model="row.scope.value"
          :class="{ 'is-error': row.scope.error }"
          required
          placeholder="请输入内容"
          @change="
            (value) => {
              changeItem(value, row.tIndex);
            }
          "
        />
      </template>
      <template v-if="row.scope.type === 8">
        <el-input
          v-model="row.scope.value"
          :class="{ 'is-error': row.scope.error }"
          type="password"
          required
          placeholder="请输入内容"
          @change="
            (value) => {
              changeItem(value, row.tIndex);
            }
          "
        />
      </template>
      <template v-if="row.scope.type === 2">
        <el-select
          v-model="row.scope.value"
          style="width: 100%"
          :class="{ 'is-error': row.scope.error }"
          placeholder="请选择内容"
          @change="
            (value) => {
              changeItem(value, row.tIndex);
            }
          "
        >
          <el-option
            v-for="item in row.scope.option"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </template>
      <template v-if="row.scope.type === 1">
        <el-select
          v-model="row.scope.value"
          multiple
          :class="{ 'is-error': row.scope.error }"
          style="width: 100%"
          placeholder="请选择内容"
          @change="
            (value) => {
              changeItem(value, row.tIndex);
            }
          "
        >
          <el-option
            v-for="item in row.scope.option"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </template>
      <template v-if="row.scope.type === 5">
        <el-date-picker
          v-model="row.scope.value"
          align="center"
          :class="{ 'is-error': row.scope.error }"
          value-format="yyyy-MM-dd"
          type="date"
          placeholder="选择日期"
          @change="
            (value) => {
              changeItem(value, row.tIndex);
            }
          "
        >
        </el-date-picker>
      </template>
      <template v-if="row.scope.type === 6">
        <el-date-picker
          v-model="row.scope.value"
          align="center"
          :class="{ 'is-error': row.scope.error }"
          type="datetime"
          value-format="yyyy-MM-dd HH-mm-ss"
          placeholder="选择日期时间"
          @change="
            (value) => {
              changeItem(value, row.tIndex);
            }
          "
        >
        </el-date-picker>
      </template>
      <template v-if="row.scope.type === 7">
        <el-date-picker
          v-model="row.scope.value"
          align="center"
          :class="{ 'is-error': row.scope.error }"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          unlink-panels
          :picker-options="pickerOptions"
          @change="
            (value) => {
              changeItem(value, row.tIndex);
            }
          "
        >
        </el-date-picker>
      </template>
      <template v-if="row.scope.type === 4">
        <el-input-number
          v-model="row.scope.value"
          :class="{ 'is-error': row.scope.error }"
          controls-position="right"
          :min="row.scope.option[0].min"
          :max="row.scope.option[0].max"
          :precision="row.scope.option[0].precision"
          @change="
            (value) => {
              changeItem(value, row.tIndex);
            }
          "
        ></el-input-number>
      </template>
      <template v-if="row.scope.type === 3">
        <number-range
          v-model="row.scope.value"
          :min="row.scope.option[0].min"
          :max="row.scope.option[0].max"
          :precision="row.scope.option[0].precision"
          :class="{ 'is-error': row.scope.error }"
          @input="
            (value) => {
              changeItem(value, row.tIndex);
            }
          "
        >
        </number-range>
      </template>
    </template>
  </BaseTable>
</template>

<script>
const NumberRange = () => import('@/components/base/NumberRange.vue')
const BaseTable = () => import('@/components/table/BaseTable')
export default {
  components: { BaseTable, NumberRange },

  props: {
    paramsLists: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  data() {
    return {
      pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }]
        }
      }
  },
  computed: {
    tableData: function() {
      const paramsLists = this.paramsLists
      return {
        fileds: [
          {
            filed: 'label',
            label: '字段名称',
            width: '120px',
            resizable: true
          },
          {
            filed: 'type',
            label: '类型',
            width: '120px',
            formatter: (row) => {
              let str = ''
              switch (row.type) {
                case 0:
                  str = '输入框'
                  break
                case 1:
                  str = '多选'
                  break
                case 2:
                  str = '下拉单选'
                  break
                case 3:
                  str = '数字范围'
                  break
                case 4:
                  str = '数字'
                  break
                case 5:
                  str = '日期'
                  break
                case 6:
                  str = '日期时间'
                  break
                case 7:
                  str = '日期范围'
                  break
                case 8:
                  str = '加密'
                  break
              }
              return str
            },
            resizable: true
          },
          {
            filed: 'required',
            label: '必填',
            width: '80px',
            formatter: (row) => {
              return row.required ? '是' : '否'
            },
            resizable: true
          },
          {
            filed: 'value',
            label: '值',
            width: '400px',
            isCustom: true,
            resizable: true
          },
          {
            filed: 'description',
            label: '描述',
            width: '',
            isCustom: true,
            resizable: true
          }
        ],
        // 0 = Input(输入框), 1 = MultiCheckBox(多选),
        // 2 = ComboBox(下拉单选), 3 = NumberScope(数字范围),
        // 4 = Number(数字), 5 = Date(日期), 6 = DateTime(日期+时间),
        // 7 = DateRange(日期范围), 8 = Password(密码),
        columns: paramsLists
      }
    }
  },
  methods: {
    changeItem(value, index) {
      const arr = [...this.tableData.columns]
      if (arr[index].error) {
        arr[index].error = false
        this.$set(this.tableData.columns, index, arr[index])
      }
    },
    validate() {
      const data = [...this.tableData.columns]
      let flag = true
      const arr = []
      if (data.length === 0) {
        flag = false
      }
      data.map((item, $index) => {
        if (item.required && !item.value) {
          item.error = true
          flag = false
          this.$set(this.tableData.columns, $index, item)
        }
        if (item.type === 1 && item.value.length === 0) {
          item.error = true
          flag = false
          this.$set(this.tableData.columns, $index, item)
        }
        if (item.type === 3) {
          if (!(item.value && item.value[0] && item.value[1])) {
            item.error = true
            flag = false
            this.$set(this.tableData.columns, $index, item)
          }
        }
        let value = ''
        if (item.value) {
          if (typeof item.value === 'string') {
            value = item.value
          } else {
            value = JSON.stringify(item.value)
          }
        }
        arr.push({
          expressSupplierDefaultParamsId: item.id,
          value,
          type: item.type,
          description: item.description
        })
      })
      if (flag) {
        return arr
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="less" scoped>
:deep(.el-table--medium .el-table__cell) {
  padding: 0;
}
:deep(.el-table th.el-table__cell.is-leaf) {
  padding: 10px 0;
}
:deep(.el-input__inner) {
  border: 0;
}
:deep(.el-table .cell) {
  padding: 0;
}
:deep(.el-date-editor.el-input, .el-date-editor.el-input__inner) {
  width: 100%;
}
:deep(.el-range-editor.el-input__inner) {
  width: 100%;
  border-radius: 0;
}
:deep(.el-input-number) {
  width: 100%;
}
.is-error {
  border: 1px solid #f56c6c;
}
</style>
