<template>
  <el-dialog
    v-drag
    :title="title"
    :visible.sync="dialogVisible"
    width="1160px"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
    top="2%"
  >
    <div class="content">
      <el-form ref="form" class="form" :model="form" :rules="rules">
        <div class="form-item">
          <label class="lable"><span class="red">* </span>模板代码</label>
          <el-form-item prop="code">
            <el-input
              v-model="form.code"
              placeholder="请输入模板代码"
            ></el-input>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>模板名称</label>
          <el-form-item prop="name">
            <el-input
              v-model="form.name"
              placeholder="请输入模板名称"
            ></el-input>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>开始时间</label>
          <el-form-item prop="validFrom">
            <el-date-picker
              v-model="form.validFrom"
              style="width: 100%"
              value-format="yyyy-MM-dd"
              align="center"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>结束时间</label>
          <el-form-item prop="validTo">
            <el-date-picker
              v-model="form.validTo"
              style="width: 100%"
              value-format="yyyy-MM-dd"
              align="center"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span> 报价类型</label>
          <el-form-item prop="type">
            <el-select
              v-model="form.type"
              style="width: 100%"
              placeholder="请选择快递服务商"
            >
              <el-option
                v-for="item in accountPriceTplOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>报价含燃油</label>
          <el-form-item prop="isFuelCharge">
            <el-switch
              v-model="form.isFuelCharge"
              active-color="#2395F3"
              inactive-color="#f1f1f1"
              :active-text="form.isFuelCharge ? '是' : '否'"
            >
            </el-switch>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span> 附加费</label>
          <el-form-item prop="isOtherCharge">
            <el-switch
              v-model="form.isOtherCharge"
              active-color="#2395F3"
              inactive-color="#f1f1f1"
              :active-text="form.isOtherCharge ? '是' : '否'"
              @change="otherCharge"
            >
            </el-switch>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span> 是否包含价格</label>
          <el-form-item prop="isTempPrice">
            <el-switch
              v-model="form.isTempPrice"
              active-color="#2395F3"
              inactive-color="#f1f1f1"
              :active-text="form.isTempPrice ? '是' : '否'"
            >
            </el-switch>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>始发地</label>
          <el-form-item>
            <el-cascader
              v-model="form.address"
              style="width: 100%"
              :options="addressDropOption"
            >
            </el-cascader>
          </el-form-item>
        </div>
      </el-form>
      <div class="tab">
        <div class="line"></div>
        <BaseTab
          style="z-index: 1"
          :tabItems="tabItems"
          :propWidth="490"
          :active="isActive"
          @tabChange="tabChange"
        ></BaseTab>
      </div>
      <div class="list">
        <div class="add">
          <div v-show="isActive === 1" class="item btn" @click="add">
            <i class="el-icon-circle-plus-outline" />
            <span>新增</span>
          </div>
          <div v-show="isActive === 0" class="item3 btn" @click="download">
            <svg-icon iconClass="download" className="icon3" />
            <span>下载价格模版</span>
          </div>
          <div v-show="isActive === 0" class="item btn" @click="uploadPrice">
            <svg-icon iconClass="upload" className="icon2" />
            <span>导入价格</span>
          </div>
          <!-- <div v-show="isActive === 0" class="item2 btn" @click="copy">
            <i class="el-icon-circle-plus-outline" />
            <span>复制价格模版</span>
          </div> -->
        </div>
        <base-table
          v-show="isActive === 0"
          :showTooltip="false"
          :height="240"
          :border="true"
          :tableData="tableData"
          :pageIndex="pagerConfig.pageIndex"
          :pageSize="pagerConfig.pageSize"
          @handlePageChange="handlePageChange"
        >
        </base-table>
        <base-table
          v-show="isActive === 1"
          :showTooltip="false"
          :isPager="false"
          :height="280"
          :border="true"
          :tableData="tableData1"
        >
          <template slot="opt" slot-scope="row">
            <div class="table-opt">
              <el-switch
                v-model="row.scope.isActived"
                active-color="#2395F3"
                inactive-color="#CDCDCD"
                :active-text="row.scope.isActived ? '启用' : '停用'"
              >
              </el-switch>
              <i
                class="el-icon-edit-outline delete"
                title="修改"
                @click="editRow(row.scope, row.tIndex)"
              ></i>
              <i
                class="el-icon-delete delete"
                title="删除"
                @click="deleteRow(row.tIndex)"
              ></i>
            </div>
          </template>
        </base-table>
      </div>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div class="ok flex_c_c" @click="ok('form')">确 定</div>
    </span>
    <upload-price-dialog
      :dialogVisible="dialogVisible1"
      :expressAccountId="expressAccountId"
      @ok="ok1"
      @cancel="cancel1"
    ></upload-price-dialog>
    <add-additional-fee-dialog
      :dialogVisible="dialogVisible2"
      :editData="rowData"
      :type="type"
      @ok="ok2"
      @cancel="cancel2"
    ></add-additional-fee-dialog>
  </el-dialog>
</template>

<script>
const BaseTab = () => import('@/components/tab/BaseTab')
const BaseTable = () => import('@/components/table/BaseTable.vue')
import { mapGetters } from 'vuex'
import config from '@/utils/config'
import UploadPriceDialog from './UploadPriceDialog.vue'
import AddAdditionalFeeDialog from './AddAdditionalFeeDialog.vue'
import {
  insertExpressAccountPriceTemplate,
  updateExpressAccountPriceTemplate,
  getExpressAccountPriceList,
  getExpressAccountOtherPriceList
} from '@/api/system'
export default {
  components: { BaseTab, BaseTable, UploadPriceDialog, AddAdditionalFeeDialog },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    supplyService: {
      type: Array,
      default: function() {
        return []
      }
    },
    expressAccountId: {
      type: Number,
      default: 0
    },
    title: {
      type: String,
      default: '新增'
    },
    editData: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      form: {
        code: '',
        name: '',
        validFrom: '',
        validTo: '',
        type: 0,
        isFuelCharge: true,
        isOtherCharge: true,
        isTempPrice: true,
        address: '',
        otherPriceList: [
        ],
        accountPriceList: [
        ]
      },
      rules: {
        code: [{ required: true, message: '请输入模板代码', trigger: 'blur' }],
        name: [{ required: true, message: '请输入模板名称', trigger: 'blur' }],
        validFrom: [
          { required: true, message: '请选择开始时间', trigger: 'change' }
        ],
        validTo: [
          { required: true, message: '请选择结束时间', trigger: 'change' }
        ],
        type: [
          { required: true, message: '请选择报价类型', trigger: 'change' }
        ]
      },
      isActive: 0,
      accountPriceTplOptions: config.accountPriceTplOptions,
      options: [
        {
          value: true,
          label: '是'
        },
        {
          value: false,
          label: '否'
        }
      ],
      tableData1: {
        fileds: [
          {
            filed: 'code',
            label: '费用项',
            width: '300',
            formatter: (row) => {
              if (row.code && row.name) {
                return row.code + '-' + row.name
              } else {
                return ''
              }
            },
            resizable: true
          },
          {
            filed: 'price',
            label: '费用',
            width: '100',
            resizable: true
          },
          {
            filed: 'cost',
            label: '成本',
            width: '100',
            resizable: true
          },
          {
            filed: 'currency',
            label: '币制',
            width: '80',
            resizable: true
          },
          {
            filed: 'calculateType',
            label: '计算方式',
            width: '110',
            formatter: (row) => {
              const item = config.calculateTypelOptions.find(
                (a) => a.value === row.calculateType
              )
              return item.label
            },
            resizable: true
          },
          {
            filed: 'caculateDimension',
            label: '计算维度',
            width: '110',
            formatter: (row) => {
              const item = config.caculateDimensionOptions.find(
                (a) => a.value === row.caculateDimension
              )
              return item.label
            },
            resizable: true
          },
          {
            filed: 'opt',
            label: '操作',
            width: '',
            isCustom: true,
            resizable: true
          }
        ],
        columns: [],
        total: 0
      },
      dialogVisible1: false,
      dialogVisible2: false,
      priceData: [],
      pagerConfig: {
        pageIndex: 1,
        pageSize: 20
      },
      isAgainImport: false,
      rowData: {},
      type: 0,
      editIndex: 0
    }
  },
  computed: {
    ...mapGetters(['addressDropOption']),
    esIdOptions: function() {
      const arr = this.supplyService.map((item) => {
        return {
          label: item.name,
          value: item.id
        }
      })
      return arr || []
    },
    esServiceIdOptions: function() {
      const service = this.supplyService.find((item) => {
        return (
          item.id === this.form.expressSupplierId ||
          this.editData.expressSupplierId
        )
      })
      const arr =
        service &&
        service.serviceTypeList.map((item) => {
          return {
            label: item.name,
            value: item.id
          }
        })
      return arr || []
    },
    cityOptions: function() {
      const arr = []
      this.addressDropOption.map((country) => {
        country.children.map((province) => {
          province.children.map((city) => {
            arr.push(city)
          })
        })
      })
      return arr
    },
    tableData: function() {
      const columns = this.priceData.slice(
        (this.pagerConfig.pageIndex - 1) * this.pagerConfig.pageSize,
        this.pagerConfig.pageIndex * this.pagerConfig.pageSize - 1
      )
      return {
        fileds: [
          {
            filed: 'zoneCode',
            label: '分区',
            width: '',
            resizable: true
          },
          {
            filed: 'cityId',
            label: '始发地',
            width: '',
            formatter: (row) => {
              let str = ''
              if (this.cityOptions) {
                const item = this.cityOptions.find(
                  (a) => a.value === row.cityId
                )
                if (item) {
                  str = item.label
                }
              }
              return str
            },
            resizable: true
          },
          {
            filed: 'weightFrom',
            label: '起始重量',
            width: '',
            resizable: true
          },
          {
            filed: 'weightTo',
            label: '终止重量',
            width: '',
            resizable: true
          },
          {
            filed: 'weightUnitCode',
            label: '重量单位',
            width: '',
            resizable: true
          },
          {
            filed: 'price',
            label: '价格',
            width: '',
            resizable: true
          },
          {
            filed: 'currency',
            label: '币制',
            width: '',
            resizable: true
          },
          {
            filed: 'createdName',
            label: '创建人',
            width: '',
            resizable: true
          },
          {
            filed: 'createdOn',
            label: '创建时间',
            width: '',
            formatter: (row) => {
              return row.createdOn.split(' ')[0]
            },
            resizable: true
          }
        ],
        columns: columns || [],
        total: this.priceData.length
      }
    },
    tabItems() {
      let arr
      if (this.form.isOtherCharge) {
        arr = [
          {
            id: 0,
            name: '价格列表'
          },
          {
            id: 1,
            name: '附加费列表'
          }
        ]
      } else {
        arr = [
          {
            id: 0,
            name: '价格列表'
          }
        ]
      }
      return arr
    }
  },
  watch: {
    dialogVisible: function(value) {
      if (value) {
        console.log(value)
        if (this.editData.id) {
          this.initUpload()
        } else {
          this.form = {
            code: '',
            name: '',
            validFrom: '',
            validTo: '',
            type: 0,
            isFuelCharge: true,
            isOtherCharge: true,
            isTempPrice: true,
            address: '',
            otherPriceList: [],
            accountPriceList: []
          }
          this.priceData = []
          this.tableData1.total = 0
          this.tableData1.columns = []
        }
      }
    }
  },
  created() {},
  methods: {
    cancel() {
      this.isActive = 0
      this.$refs['form']?.resetFields()
      this.$emit('cancel')
    },
    ok(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.form.isOtherCharge) {
            if (this.tableData1.columns.length === 0) {
              this.$message.error('请填写附加费')
              return
            }
          }
          const arr = this.tableData1.columns.map((item) => {
            return {
              ...item,
              name: item.code.indexOf('-') > -1 ? item.code.split('-')[1] : item.name,
              code: item.code.split('-')[0]
            }
          })
          const newList = []
          let flag = false
          for (const a of this.tableData1.columns) {
            if (newList.find(b => b.code === a.code)) {
              flag = true
              continue
            }
            newList.push(a)
          }
          if (flag) {
            this.$message.warning('存在相同的附加费设定')
            return
          }
          const parmas = {
            ...this.form,
            countryId: this.form.address[0],
            originCityId: this.form.address[2],
            otherPriceList: [...arr],
            accountPriceList: [...this.priceData],
            expressAccountId: this.expressAccountId
          }
          if (this.editData.id) {
            updateExpressAccountPriceTemplate({
                ...parmas,
                isAgainImport: this.isAgainImport
            }).then((res) => {
              console.log(res)
              if (res.success) {
                this.$message.success('修改成功')
                this.$refs['form']?.resetFields()
                this.$emit('ok')
              } else {
                this.$message.error(res.errorMessage)
              }
            })
          } else {
            insertExpressAccountPriceTemplate(parmas).then((res) => {
              console.log(res)
              if (res.success) {
                this.$message.success('新增成功')
                this.$refs['form']?.resetFields()
                this.$emit('ok')
              } else {
                this.$message.error(res.errorMessage)
              }
            })
          }
        }
      })
    },
    tabChange(item) {
      this.isActive = item.id
    },
    add() {
      this.type = 0
      this.dialogVisible2 = true
    },
    ok2(data) {
      this.rowData = {}
      if (this.type) {
        this.$set(this.tableData1.columns, this.editIndex, data)
      } else {
        this.tableData1.columns.push(data)
      }
      this.cancel2()
    },
    cancel2() {
      this.rowData = {}
      this.dialogVisible2 = false
    },
    uploadPrice() {
      this.isAgainImport = true
      this.dialogVisible1 = true
    },
    ok1(data) {
      this.priceData = data
      this.cancel1()
    },
    cancel1() {
      this.dialogVisible1 = false
    },
    deleteRow(tIndex) {
      this.tableData1.columns.splice(tIndex, 1)
    },
    handlePageChange(pager) {
      this.pagerConfig = {
        ...this.pagerConfig,
        ...pager
      }
    },
    async download() {
      const elink = document.createElement('a')
      elink.style.display = 'none'
      elink.href =
        `/backEnd/api/${config.supplyCode}/file/downLoadTemplate?guid=fde1dc1a9e2fe03857e1388afc8c2a31`
      document.body.appendChild(elink)
      elink.click()
      URL.revokeObjectURL(elink.href)
      document.body.removeChild(elink)
    },
    async initUpload() {
      try {
        const res = await getExpressAccountPriceList({
          expressAccountPriceTemplateId: this.editData.id
        })
        if (res.success) {
          this.priceData = res.data
        } else {
          this.$message.error(res.errorMessage)
        }
        const res1 = await getExpressAccountOtherPriceList({
          expressAccountPriceTemplateId: this.editData.id
        })
        if (res1.success) {
          this.tableData1.columns = res1.data
        } else {
          this.$message.error(res1.errorMessage)
        }
        let provinceId = 0
        const country = this.addressDropOption.find(a => {
          return a.value === this.editData.countryId
        })
        country.children.find(a => {
          a.children.forEach(item => {
            if (item.value === this.editData.originCityId) {
              provinceId = a.value
            }
          })
        })
        this.form = {
          ...this.editData,
          address: [this.editData.countryId, provinceId, this.editData.originCityId]
        }
      } catch (error) {
        console.log(error)
      }
    },
    editRow(row, index) {
      this.rowData = row
      this.dialogVisible2 = true
      this.type = 1
      this.editIndex = index
    },
    otherCharge() {
      if (!this.form.isOtherCharge) {
        this.isActive = 0
      }
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: calc(100% - 80px);
  height: auto;
  margin: 0 auto;
  padding: 40px;
  text-align: left;
  .form {
    display: flex;
    width: 100%;
    height: auto;
    flex-wrap: wrap;
  }
  .form-item {
    width: calc(20% - 16px);
    position: relative;
    margin-left: 20px;
    .lable {
      width: 100%;
      height: 19px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      margin-bottom: 5px;
    }
    .red {
      color: rgba(255, 116, 68, 1);
    }
    &:nth-child(5n + 1) {
      margin-left: 0px;
    }
  }
  .tab {
    width: 100%;
    height: 40px;
    position: relative;
    .line {
      width: 100%;
      height: 1px;
      background: #eeeeee;
      position: absolute;
      top: 19px;
    }
  }
  .list {
    width: 100%;
    max-height: 320px;
    margin-top: 40px;
    .add {
      width: 100%;
      height: 40px;
      margin: 20px 0;
      display: flex;
      flex-direction: row-reverse;
      .item {
        width: 100px;
        height: 40px;
        background: #35ba83;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
        span {
          margin-left: 5px;
        }
      }
      .item2 {
        width: 140px;
        height: 40px;
        background: #2395f3;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
        span {
          margin-left: 5px;
        }
      }
      .item3 {
        width: 140px;
        height: 38px;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #35ba83;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #35ba83;
        line-height: 38px;
        text-align: center;
        cursor: pointer;
        span {
          margin-left: 5px;
        }
      }
      .btn {
        & + .btn {
          margin-left: 10px;
        }
      }
    }
    .icon {
      font-size: 20px;
      & + .icon {
        margin-left: 20px;
      }
    }
    .icon2 {
      font-size: 20px;
      color: #ffffff;
    }
    .icon3 {
      font-size: 20px;
      color: #35ba83;
    }
    /deep/ .fTable .el-table {
      border-radius: 4px 4px 4px 4px;
      overflow: hidden;
    }
    /deep/ .fp-cell {
      border: 0;
    }
  }
  .switch-text {
    width: 14px;
    height: 19px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    line-height: 20px;
    margin-left: 13px;
  }
  .delete {
    font-size: 20px;
    color: #2395f3;
    margin-left: 20px;
    cursor: pointer;
    vertical-align: bottom;
  }
}

.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #EEEEEE;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
.numberRange {
  width: 175px;
}
.numberRange:deep(.el-input__inner) {
  border: 0;
  height: 38px;
  line-height: 38px;
}
/deep/.el-form-item__content {
  line-height: 0;
}
</style>
