<template>
  <el-dialog
    v-drag
    :title="accountTemTitle"
    :visible.sync="dialogVisible"
    width="1120px"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="content">
      <div class="form">
        <div class="form-item">
          <label class="lable"><span class="red">* </span>模板名称</label>
          <el-input
            v-model="form.templateName"
            style="width: 320px;"
            :disabled="accountTemTitle === '详情'"
            placeholder="请输入模板名称"
          >
          </el-input>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>通知邮箱</label>
          <el-popover
            v-model="popover"
            popper-class="email-popover"
            placement="bottom"
            width="500"
            :disabled="accountTemTitle === '详情' ? true : false"
            :visible-arrow="false"
            trigger="click">
            <div class="email-content">
              <BaseMutiInput
                ref="baseMutiInput"
                v-model="form.notifyEmail"
                :default="form.notifyEmail"
              ></BaseMutiInput>
              <div class="footers">
                <div class="cancel flex_c_c" @click="cancel1">取消</div>
                <div class="ok flex_c_c" @click="ok1">确定</div>
              </div>
            </div>
            <div slot="reference" class="cusInput">
              <el-tag
                v-for="tag in form.notifyEmail.filter(a => a !== '').slice(0, 2)"
                :key="tag"
                type="info"
                :closable="accountTemTitle !== '详情'"
                :disable-transitions="false"
                @close="handleClose(tag)">
                {{ tag }}
              </el-tag>
              <div v-if="notifyEmailLength && accountTemTitle !== '详情'" class="num-remain flex_c_c">
                +{{ form.notifyEmail.filter(a => a !== '').slice(2).length }}
              </div>
              <el-popover
                v-else-if="notifyEmailLength && accountTemTitle === '详情'"
                placement="bottom"
                width="280"
                trigger="hover">
                <div class="email-box">
                  <div class="email-header flex_c_c">通知邮箱</div>
                  <ul class="email-body">
                    <li v-for="item in form.notifyEmail.filter(a => a !== '').slice(2)" :key="item" v-tooltip="238">{{ item }}</li>
                  </ul>
                </div>
                <div slot="reference" class="num-remain flex_c_c">
                  ···
                </div>
              </el-popover>
            </div>
          </el-popover>
        </div>
        <div v-if="accountTemTitle !== '详情'" class="add flex_c_c" @click="dialogVisible1 = true">
          <svg-icon className="addicon" iconClass="add" />添加账号
        </div>
      </div>
      <div class="data">
        <BaseTable
          height="440"
          :tableData="{...tableData, fileds}"
          :isPager="false"
          :border="true"
          @dblclick="dblclick"
        >
          <template slot="isActived" slot-scope="row">
            <span :style="{ color: row.scope.isActived ? '#2395F3' : '#999999'}">{{ row.scope.isActived ? '启用' : '停用' }}</span>
          </template>
          <template slot="deliveryRate" slot-scope="row">
            <template v-if="!row.scope.isEdit || accountTemTitle === '详情'">
              {{ row.scope.deliveryRate }}
            </template>
            <template v-else>
              <el-input v-model="row.scope.deliveryRate" @blur="row.scope.isEdit = false"></el-input>
            </template>
          </template>
          <template v-if="accountTemTitle !== '详情'" slot="deliveryRate-header">
            <el-tooltip class="item" effect="dark" content="双击派送折扣列可编辑" placement="top">
              <img src="@/assets/images/system/edit.png" style="margin-left: 5px;">
            </el-tooltip>
          </template>
          <template slot="opt" slot-scope="row">
            <img class="delete" src="@/assets/images/const/delete.png" title="删除" @click="deleteRow(row.tIndex)">
          </template>
        </BaseTable>
      </div>
    </div>
    <span v-if="accountTemTitle !== '详情'" slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取消</div>
      <div v-loading="loading" class="ok flex_c_c" @click="ok('form')">确定</div>
    </span>
    <AddAccountDialog
      :dialogVisible="dialogVisible1"
      :selectAccountList="tableData.columns"
      @cancel="dialogVisible1 = false"
      @ok="ok2"
    ></AddAccountDialog>
  </el-dialog>
</template>

<script>
const BaseTable = () => import('@/components/table/BaseTable')
const BaseMutiInput = () => import('@/components/base/BaseMutiInput')
import AddAccountDialog from './AddAccountDialog.vue'
import { isValidEmail } from '@/utils/validate'
import {
  getExpressAccountTempalteItem,
  insertExpressAccountTemplate,
  updateExpressAccountTemplate
} from '@/api/system'
export default {
  components: {
    BaseTable,
    BaseMutiInput,
    AddAccountDialog
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    editData: {
      type: Object,
      default: () => {}
    },
    accountTemTitle: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      tableData: {
        fileds: [
          {
            filed: 'accountNumber',
            label: '账号',
            width: '180',
            resizable: true
          },
          {
            filed: 'serviceProviderName',
            label: '快递商',
            width: '180',
            resizable: true
          },
          {
            filed: 'serviceTypeName',
            label: '服务类型',
            width: '180',
            resizable: true
          },
          {
            filed: 'supplierName',
            label: '账号供应商',
            width: '180',
            resizable: true
          },
          {
            filed: 'isActived',
            label: '状态',
            isCustom: true,
            resizable: true
          },
          {
            filed: 'deliveryRate',
            label: '派送折扣',
            isCustom: true,
            resizable: true
          }
        ],
        columns: []
      },
      form: {
        templateName: '',
        notifyEmail: []
      },
      rules: {
        templateName: [
          {
            required: true,
            message: '请输入模板名称',
            trigger: 'blur'
          }
        ],
        notifyEmail: [
          {
            required: true,
            message: '请输入通知邮箱',
            trigger: 'blur'
          }
        ]
      },
      popover: false,
      dialogVisible1: false,
      loading: false
    }
  },
  computed: {
    notifyEmailLength() {
      return this.form.notifyEmail.filter(a => a !== '').slice(2).length
    },
    fileds() {
      let arr = []
      if (this.accountTemTitle !== '详情') {
        arr = [
          ...this.tableData.fileds,
          {
            filed: 'opt',
            label: '操作',
            isCustom: true
          }
        ]
      } else {
        arr = this.tableData.fileds
      }
      return arr
    }
  },
  watch: {
    dialogVisible(val) {
      if (val && this.editData.id) {
        this.form.templateName = this.editData.templateName
        this.form.notifyEmail = this.editData.notifyEmail.split(';')
        this.$nextTick(() => {
          this.$refs.baseMutiInput.valueArr = [...this.form.notifyEmail, '']
        })
        this.getLists(this.editData.id)
      } else {
        this.form = {
          templateName: '',
          notifyEmail: []
        }
        this.tableData.columns = []
        this.$nextTick(() => {
          this.$refs.baseMutiInput.reset()
        })
      }
    }
  },
  methods: {
    handleClose(tag) {
      this.form.notifyEmail.splice(this.form.notifyEmail.indexOf(tag), 1)
      this.$refs.baseMutiInput.valueArr = [...this.form.notifyEmail, '']
    },
    async getLists(id) {
      try {
        const res = await getExpressAccountTempalteItem({ tempalteId: id })
        if (res.success) {
          this.tableData.columns = res.data.map(a => {
            return {
              ...a,
              isEdit: false
            }
          })
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    cancel() {
      this.form = {
        templateName: '',
        notifyEmail: []
      }
      this.$refs.baseMutiInput.reset()
      this.tableData.columns = []
      this.$emit('cancel')
    },
    async ok() {
      if (!this.form.templateName || !this.form.notifyEmail.length) {
        this.$message.error('请将信息填写完整')
        return
      }
      if (!this.tableData.columns.length) {
        this.$message.error('请添加账号')
        return
      }
      let flag = true
      this.form.notifyEmail.forEach(a => {
        if (!isValidEmail(a)) flag = false
      })
      if (!flag) {
        this.$message.error('请填写正确格式的邮箱')
        return
      }
      this.loading = true
      try {
        const params = {
          id: this.editData.id ? this.editData.id : undefined,
          templateName: this.form.templateName,
          notifyEmail: this.form.notifyEmail,
          items: this.tableData.columns.map(a => {
            return {
              expressAccountId: a.expressAccountId,
              deliveryRate: a.deliveryRate
            }
          })
        }
        let res
        if (this.accountTemTitle === '新增模板') {
          res = await insertExpressAccountTemplate(params)
        } else {
          res = await updateExpressAccountTemplate(params)
        }
        this.loading = false
        if (res.success) {
          this.$emit('ok')
          this.$message.success(res.errorMessage)
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        this.loading = false
        console.error(error)
      }
    },
    cancel1() {
      this.form.notifyEmail = []
      this.$refs.baseMutiInput.reset()
      this.popover = false
    },
    ok1() {
      this.popover = false
    },
    ok2(arr) {
      this.tableData.columns.push(...arr)
      this.dialogVisible1 = false
    },
    deleteRow(index) {
      this.tableData.columns.splice(index, 1)
    },
    dblclick(row, column) {
      if (column.label !== '派送折扣') return
      row.isEdit = true
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  height: auto;
  .form {
    display: flex;
    width: 100%;
    height: auto;
    flex-wrap: wrap;
    .el-form-item {
      margin: 0;
      margin-right: 20px;
    }
    .add {
      align-self: flex-end;
      width: 100px;
      height: 40px;
      background: #35BA83;
      border-radius: 4px 4px 4px 4px;
      cursor: pointer;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
      .addicon {
        font-size: 20px;
      }
    }
    .cusInput {
      display: flex;
      align-items: center;
      width: 500px;
      height: 40px;
      background: #FFFFFF;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #DCDFE6;
      box-sizing: border-box;
      padding: 5px;
      cursor: pointer;
      .el-tag {
        height: 30px;
        margin-right: 5px;
        font-size: 14px;
        color: #666666;
      }
      .num-remain {
        width: 40px;
        height: 30px;
        background: #F7F7F7;
        border-radius: 2px 2px 2px 2px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #666666;
      }
    }
    .form-item {
      position: relative;
      margin-right: 20px;
      .lable {
        display: block;
        width: 67px;
        height: 19px;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
        margin-bottom: 5px;
      }
      .red {
        color: rgba(255, 116, 68, 1);
      }
    }
  }
  .data {
    margin-top: 10px;
    border-radius: 4px;
    overflow: hidden;
    .delete {
      cursor: pointer;
    }
  }
}
/deep/ .el-dialog__header {
  border: 0;
}
/deep/ .el-dialog__body {
  padding-top: 0;
}
.email-content {
  .footers {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    .cancel {
      width: 100px;
      height: 40px;
      background: #EEEEEE;
      border-radius: 4px 4px 4px 4px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      margin-right: 16px;
      cursor: pointer;
    }
    .ok {
      width: 100px;
      height: 40px;
      background: #2395f3;
      border-radius: 4px 4px 4px 4px;
      font-size: 14px;
      cursor: pointer;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #EEEEEE;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>
<style lang="less">
.email-popover {
  top: 211px !important;
  padding: 20px 20px 30px 20px;
  box-sizing: border-box;
  margin: 0;
}
.email-box {
  width: 240px;
  height: 240px;
  background: #FFFFFF;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #EEEEEE;
  box-sizing: border-box;
  margin: 0 auto;
  overflow: hidden;
}
.email-header {
  width: 100%;
  height: 40px;
  background: #F7F7F7;
  border-bottom: 1px solid #EEEEEE;
  box-sizing: border-box;
}
.email-body {
  width: 100%;
  height: calc(100% - 40px);
  overflow-y: auto;
  li {
    width: 238px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-bottom: 1px solid #EEEEEE;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
