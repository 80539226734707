<template>
  <el-dialog
    v-drag
    title="新增下属客户"
    :visible.sync="dialogVisible"
    width="520px"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="content">
      <el-form ref="ruleForm" label-position="top" :model="form" :rules="rules">
        <el-form-item label="选择客户" prop="companyId">
          <el-select v-model="form.companyId" placeholder="请选择客户" style="width: 400px;">
            <el-option
              v-for="item in companyDrapdown"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="派送折扣" prop="deliveryRate">
          <el-input v-model="form.deliveryRate" placeholder="请输入派送折扣" style="width: 400px;">
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div class="ok flex_c_c" @click="ok('ruleForm')">确 定</div>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { addCEA } from '@/api/system'
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    editData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      form: {
        companyId: '',
        deliveryRate: ''
      },
      rules: {
        companyId: [
          { required: true, message: '请选择客户', trigger: 'change' }
        ],
        deliveryRate: [
          { required: true, message: '请输入派送折扣', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['companyDrapdown'])
  },
  created() {
    if (!this.companyDrapdown.length) {
      this.$store.dispatch('getCompanyDrapdown')
    }
  },
  methods: {
    cancel() {
      this.$refs['ruleForm'].resetFields()
      this.$emit('cancel')
    },
    ok(formName) {
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          try {
            const { companyId, deliveryRate } = this.form
            const obj = {
              companyId, deliveryRate,
              expressAccountIds: [this.editData.id]
            }
            const res = await addCEA(obj)
            if (res.success) {
              this.$emit('ok')
              this.$message.success(res.errorMessage)
              this.$refs['ruleForm'].resetFields()
            } else {
              this.$message.error(res.errorMessage)
            }
          } catch (error) {
            console.error(error)
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: calc(100% - 80px);
  height: auto;
  margin: 0 auto;
  .opt {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;
    & div {
      height: 40px;
      border-radius: 4px 4px 4px 4px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
      cursor: pointer;
      img {
        width: 20px;
        height: 20px;
      }
      svg {
        font-size: 20px;
      }
    }
    .copy {
      width: 150px;
      background: #2395F3;
      margin-right: 10px;
    }
    .add {
      width: 80px;
      background: #35BA83;
    }
  }
  .data {
    width: 100%;
    height: auto;
    /deep/ .fTable .el-table {
      border-radius: 4px 4px 4px 4px;
      overflow: hidden;
      .fp-cell {
        border: 0;
      }
    }
  }
}
.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #EEEEEE;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 20px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>
