<template>
  <el-dialog
    v-drag
    title="添加账号"
    :visible.sync="dialogVisible"
    width="800px"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="content">
      <el-form ref="form" :model="form" :rules="rules" class="form">
        <el-form-item label="账号" prop="accountNumber">
          <el-select
            v-model="form.accountNumber"
            multiple
            filterable
            clearable
            placeholder="请选择账号"
            style="width: 100%">
            <el-option v-for="item in accountList" :key="item.id" :value="item.accountNumber" class="account-item">
              账号：{{ item.accountNumber }}
              <div>{{ item.serviceProviderName + '-' + item.serviceTypeName }}</div>
            </el-option>
          </el-select>
        </el-form-item>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>派送折扣
            <el-tooltip class="item" effect="dark" content="1.05=5%*价格表" placement="right">
              <img src="@/assets/images/bill/suggest.png">
            </el-tooltip>
          </label>
          <el-form-item prop="deliveryRate">
            <el-input-number
              v-model="form.deliveryRate"
              style="width: 100%;"
              controls-position="right"
              :min="1"
              :max="10"
              :precision="2"
              :step="0.1"
            >
            </el-input-number>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取消</div>
      <div class="ok flex_c_c" @click="ok('form')">确定</div>
    </span>
  </el-dialog>
</template>

<script>
// const BaseTable = () => import('@/components/table/BaseTable')
import {
  expressAccountList
} from '@/api/system'
export default {
  components: {
    // BaseTable
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    selectAccountList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      form: {
        accountNumber: [],
        deliveryRate: ''
      },
      rules: {
        accountNumber: [
          {
            required: true,
            message: '请选择账号',
            trigger: 'change'
          }
        ],
        deliveryRate: [
          {
            required: true,
            message: '请输入折扣',
            trigger: 'blur'
          }
        ]
      },
      accountList: []
    }
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.getAccountList()
      }
    }
  },
  methods: {
    async getAccountList() {
      try {
        const res = await expressAccountList()
        if (res.success) {
          const arr = []
          const selectAccountArr = this.selectAccountList.map(a => a.accountNumber)
          res.data && res.data.forEach(item => {
            if (selectAccountArr.indexOf(item.accountNumber) < 0) {
              arr.push({
                ...item
              })
            }
          })
          console.log(this.selectAccountList)
          this.accountList = arr
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    cancel() {
      this.$refs['form'].resetFields()
      this.$emit('cancel')
    },
    ok(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const arr = []
          this.accountList.forEach(a => {
            if (this.form.accountNumber.indexOf(a.accountNumber) > -1) {
              arr.push({
                ...a,
                deliveryRate: this.form.deliveryRate,
                expressAccountId: a.id,
                isEdit: false
              })
            }
          })
          this.$emit('ok', arr)
          this.$refs[formName].resetFields()
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  height: auto;
  .form {
    .form-item {
      position: relative;
      .lable {
        display: flex;
        align-items: center;
        width: 100%;
        height: 40px;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 40px;
      }
      .red {
        color: rgba(255, 116, 68, 1);
      }
      img {
        width: 20px;
        height: 20px;
        margin-left: 5px;
      }
    }
  }
}
.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #EEEEEE;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>
<style lang="less">
.email-popover {
  top: 211px !important;
  padding: 20px 20px 30px 20px;
  box-sizing: border-box;
  margin: 0;
}
.account-item {
  height: auto;
  border-bottom: 1px solid #EEEEEE;
  box-sizing: border-box;
  &:first-child {
    border-top: 1px solid #EEEEEE;
  }
}
</style>
