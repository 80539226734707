var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("div", { staticClass: "add" }, [
        _c("div", { staticClass: "item", on: { click: _vm.add } }, [
          _c("i", { staticClass: "el-icon-circle-plus-outline" }),
          _c("span", [_vm._v("新增")]),
        ]),
      ]),
      _c("BaseTable", {
        attrs: {
          isPager: false,
          showTooltip: false,
          height: 240,
          border: true,
          tableData: _vm.tableData,
        },
        scopedSlots: _vm._u([
          {
            key: "isActived",
            fn: function (row) {
              return [
                _c("div", {
                  staticClass: "circle",
                  style: {
                    "background-color": row.scope.isActived
                      ? " #35BA83"
                      : "#FE453D",
                  },
                }),
                _vm._v(
                  " " + _vm._s(row.scope.isActived ? "启用" : "停用") + " "
                ),
              ]
            },
          },
          {
            key: "opt",
            fn: function (row) {
              return [
                _c("i", {
                  staticClass: "el-icon-delete",
                  staticStyle: { color: "#2395F3" },
                  on: {
                    click: function ($event) {
                      return _vm.deleteRow(row.scope)
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
      _c("add-account-restrictions-dialog", {
        attrs: { dialogVisible: _vm.dialogVisible },
        on: { ok: _vm.ok, cancel: _vm.cancel },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }